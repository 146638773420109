angular
    .module("admin.semesters.semester.contracts.contract", [
        "admin.semesters.semester.contracts.contract.predefined-fields",
        "admin.semesters.semester.contracts.contract.delete",
        "admin.semesters.semester.contracts.contract.duplicate",
        "admin.semesters.semester.contracts.contract.revisions",
        "admin.semesters.semester.contracts.contract.instructor",
        "admin.semesters.semester.contracts.contract.cancel",
        "admin.semesters.semester.contracts.contract.notes.delete",
        "admin.semesters.semester.contracts.contract.courses",
        "app.factories.audits",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.contracts.contract", {
            url: "/{contractId:int}?page",
            templateUrl: "admin/semesters/contracts/contract/contract.tmpl.html",
            controller: "AdminSemesterContractCtrl as contractCtrl",
            ncyBreadcrumb: {
                label: "Contract",
            },
            resolve: {
                contract: [
                    "$state",
                    "$stateParams",
                    "$q",
                    "ContractsModel",
                    "Loader",
                    function ($state, $stateParams, $q, ContractsModel, Loader) {
                        var contractId = $stateParams.contractId;
                        var deferred = $q.defer();

                        Loader.showLoading("Loading Contract");
                        ContractsModel.getContract(contractId)
                            .then(
                                function (contract) {
                                    deferred.resolve(contract);
                                },
                                function () {
                                    deferred.reject();
                                    $state.go("admin.contracts");
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });

                        return deferred.promise;
                    },
                ],
            },
        });
    })
    .controller("AdminSemesterContractCtrl", [
        "currentUser",
        "semester",
        "contract",
        "$scope",
        "$state",
        "$stateParams",
        "$q",
        "$uibModal",
        "$window",
        "$location",
        "$timeout",
        "$anchorScroll",
        "SemestersModel",
        "ContractsModel",
        "InstructorsModel",
        "ContractsFactory",
        "InstructorsFactory",
        "ProposalsModel",
        "CoursesModel",
        "NotesModel",
        "Loader",
        "AlertFactory",
        "NotesFactory",
        "AuditsFactory",
        function (
            currentUser,
            semester,
            contract,
            $scope,
            $state,
            $stateParams,
            $q,
            $uibModal,
            $window,
            $location,
            $timeout,
            $anchorScroll,
            SemestersModel,
            ContractsModel,
            InstructorsModel,
            ContractsFactory,
            InstructorsFactory,
            ProposalsModel,
            CoursesModel,
            NotesModel,
            Loader,
            AlertFactory,
            NotesFactory,
            AuditsFactory
        ) {
            var contractCtrl = this;
            var semesterId = $stateParams.semesterId;
            var contractId = $stateParams.contractId;
            var enrollment = null;

            if ($stateParams.page) {
                $scope.$emit("pageUpdated", $stateParams.page);
            }

            contractCtrl.currentUser = currentUser;
            contractCtrl.contract = contract;
            contractCtrl.settings = ContractsFactory.getSettings();
            ContractsFactory.watchSettings($scope, contractCtrl.settings);

            function initializeEditableContract() {
                contract.editable = ContractsFactory.getEditable(contract);
                $scope.contract = contract;
            }

            initializeEditableContract();

            $scope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState) {
                if (toState.name === "admin.semesters.semester.contracts.contract") {
                    initializeEditableContract();
                }
            });

            if ($location.hash()) {
                $timeout(function () {
                    $anchorScroll();
                });
            }

            $scope.$on("instructorRevisionIdUpdated", function (event, instructorRevisionId) {
                contractCtrl.instructorRevisionId = instructorRevisionId;
            });

            contractCtrl.showInstructorView = function () {
                if (contractCtrl.instructorRevisionId) {
                    $state.go("admin.semesters.semester.contracts.contract.instructor.revision", {
                        revisionId: contractCtrl.instructorRevisionId,
                    });
                } else {
                    $state.go("admin.semesters.semester.contracts.contract.instructor");
                }
            };

            $scope.$on("$stateChangeSuccess", function (event, toState) {
                if (toState.name === "admin.semesters.semester.contracts.contract") {
                    $scope.contract = contract;
                }
            });

            contractCtrl.saveEdit = function (contract, editable) {
                contractCtrl.lastSaved = "Saving...";

                var oldVal = contract[editable.key];

                editable.saveEdit().then(
                    function (updatedContract) {
                        if (editable.isCreating) {
                            return;
                        }

                        if (updatedContract) {
                            _.assign(contract, updatedContract);
                            $scope.$emit("contracts.update", updatedContract);
                            contractCtrl.audits[editable.key] = AuditsFactory.formatAudit(
                                ContractsFactory,
                                editable.key,
                                oldVal,
                                editable.value,
                                updatedContract.updated_at
                            );
                        }
                    },
                    function (result) {
                        AlertFactory.show(result.errors, "danger");

                        $timeout(function () {
                            contractCtrl.contractForm[editable.key].$invalid = true;
                        });
                    }
                );
            };

            contractCtrl.deleteContract = function () {
                var modalInstance = $uibModal.open({
                    templateUrl: "admin/semesters/contracts/contract/delete/contract-delete.tmpl.html",
                    controller: "DeleteContractCtrl as deleteContractCtrl",
                });

                modalInstance.result
                    .then(function () {
                        Loader.showLoading("Deleting Contract");
                        ContractsModel.deleteContract(contractId)
                            .then(
                                function () {
                                    _.remove(semester.contracts, function (contract) {
                                        return contract.id == contractId;
                                    });
                                    $scope.$emit("contracts.update");
                                    $state.go("admin.semesters.semester.contracts");
                                },
                                function (result) {
                                    if (result.errors) {
                                        AlertFactory.show(
                                            _(result.errors).values().flatten().value().join("\r\n"),
                                            "danger"
                                        );
                                    }
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });
                    })
                    .catch(function (result) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                    });
            };

            /**
             * Help Info
             */

            contractCtrl.removeFromProposal = function (contract) {
                $uibModal
                    .open({
                        templateUrl: "admin/semesters/contracts/contract/proposal/proposal-remove.tmpl.html",
                        controller: function ($uibModalInstance, contract) {
                            var modalCtrl = this;

                            modalCtrl.contract = contract;

                            modalCtrl.remove = function () {
                                $uibModalInstance.close();
                            };

                            modalCtrl.cancel = function () {
                                $uibModalInstance.dismiss();
                            };
                        },
                        controllerAs: "modalCtrl",
                        resolve: {
                            contract: function () {
                                return contract;
                            },
                        },
                    })
                    .result.then(
                        function () {
                            Loader.showLoading("Loading Proposal");
                            SemestersModel.getProposal(semesterId, contract.proposal.id)
                                .then(function (proposal) {
                                    Loader.showLoading("Removing Contract");
                                    ProposalsModel.removeProposalContracts(contract.proposal.id, {
                                        contracts: [contract.id],
                                    })
                                        .then(
                                            function (editedProposal) {
                                                _.merge(proposal, editedProposal);

                                                contractCtrl.contract.proposal = null;
                                                contractCtrl.contract.approvals = [];

                                                contractCtrl.contract.editable = ContractsFactory.getEditable(
                                                    contractCtrl.contract
                                                );

                                                $scope.$emit("contracts.update");

                                                contractCtrl.isUpdateInstructorCollapsed = true;
                                            },
                                            function (result) {
                                                if (result.errors) {
                                                    AlertFactory.show(
                                                        _(result.errors).values().flatten().value().join("\r\n"),
                                                        "danger"
                                                    );
                                                }
                                            }
                                        )
                                        .finally(function () {
                                            Loader.hideLoading();
                                        });
                                })
                                .finally(function () {
                                    Loader.hideLoading();
                                });
                        },
                        function () {}
                    );
            };

            contractCtrl.getDeclinedMessage = function (contract) {
                var approval = _(contract.approvals).orderBy("updated_at", "desc").first();
                var message = approval.message;
                var approver = approval.approver.user.first_name + " " + approval.approver.user.last_name;
                var html = "<div>Contract has been declined by " + approver + ": " + message + "</div>";
                return html;
            };

            /**
             * Enrollments
             */

            function getEnrollment(contract) {
                var deferred = $q.defer();

                Loader.showLoading("Loading Enrollments");
                SemestersModel.getEnrollments(semesterId)
                    .then(
                        function (enrollments) {
                            enrollment = _.find(enrollments.data, function (enrollment) {
                                return enrollment.lesson_no === contract.lesson_no;
                            });

                            deferred.resolve(enrollment);
                        },
                        function () {
                            deferred.reject();
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });

                return deferred.promise;
            }

            contractCtrl.getEnrlTotal = function (contract, field) {
                getEnrollment(contract).then(function (enrollment) {
                    if (enrollment) {
                        contract.editable[field].value = enrollment.lesson_enrl_total;
                    }
                });
            };

            contractCtrl.getEnrlCap = function (contract, field) {
                getEnrollment(contract).then(function (enrollment) {
                    if (enrollment) {
                        contract.editable[field].value = enrollment.lesson_enrl_cap;
                    }
                });
            };

            /**
             * Update Instructor
             */

            contractCtrl.showReplaceInstructor = function () {
                contractCtrl.newInstructor = {
                    ums_id: "",
                };
                contractCtrl.isReplacingInstructor = true;
                contractCtrl.settings.isInstructorOpen = true;
            };

            function updateInstructorFields(instructor, editable, isSync) {
                var userKeys = ["ums_id", "email", "first_name", "last_name"];
                var instructorKeys = [
                    "college",
                    "department",
                    "unit",
                    "position_no",
                    "rank",
                    "patfa_service_credits",
                    "job_code",
                    "is_de_adj_eligible",
                    "is_de_adj_grandfathered",
                    "has_fhc_fee",
                    "is_online_faculty",
                    "is_gold_faculty",
                    "address",
                    "city",
                    "state",
                    "zip",
                ];

                // Add credit_hr_rate only for replace
                if (!isSync) {
                    instructorKeys.push("credit_hr_rate");
                }

                _.forEach(userKeys, function (key) {
                    editable["user_" + key].value = instructor.user[key];
                    editable["user_" + key].saveEdit();
                });

                _.forEach(instructorKeys, function (key) {
                    editable["instructor_" + key].value = instructor[key];
                    editable["instructor_" + key].saveEdit();
                });

                contractCtrl.isReplacingInstructor = false;
                contractCtrl.settings.isInstructorOpen = true;
            }

            function fetchAndUpdateInstructor(umsId, editable, isSync) {
                return InstructorsModel.getInstructorByUmsId(umsId)
                    .then(
                        function (instructor) {
                            updateInstructorFields(instructor, editable, isSync);
                            return instructor;
                        },
                        function (result) {
                            AlertFactory.show(result.errors, "danger");
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            }

            contractCtrl.syncInstructor = function (umsId, editable) {
                Loader.showLoading("Syncing Instructor");

                fetchAndUpdateInstructor(umsId, editable, true).then(function (instructor) {
                    var currentRate = parseFloat(editable.instructor_credit_hr_rate.value).toFixed(4);
                    var systemRate = parseFloat(instructor.credit_hr_rate).toFixed(4);

                    var message = "Sync complete. Credit Hour Rate was not updated.";

                    // Add extra information about the current and system credit hour rates
                    message += " Current value: " + currentRate + ", System value: " + systemRate + ".";

                    AlertFactory.show(message, "success");
                });
            };

            contractCtrl.replaceInstructor = function (umsId, editable) {
                Loader.showLoading("Replacing Instructor");

                if (editable.isCreating) {
                    fetchAndUpdateInstructor(umsId, editable, false);
                } else {
                    ContractsModel.updateContractInstructor(contractId, { ums_id: umsId })
                        .then(
                            function (updatedContract) {
                                _.assign(contractCtrl.contract, updatedContract);
                                initializeEditableContract();
                                contractCtrl.isReplacingInstructor = false;
                                contractCtrl.settings.isInstructorOpen = true;
                                $scope.$emit("contracts.update", updatedContract);
                            },
                            function (result) {
                                AlertFactory.show(result.errors, "danger");
                                contractCtrl.replaceInstructorForm.ums_id.$invalid = true;
                            }
                        )
                        .finally(function () {
                            Loader.hideLoading();
                        });
                }
            };

            /**
             * Cancellation
             */

            contractCtrl.canCanc = function (contract) {
                if (contractCtrl.isApproved || contractCtrl.isAccepted) {
                    return false;
                }

                return true;
            };

            contractCtrl.isCancDisabled = function (contract) {
                if (!contract) {
                    return true;
                }

                var checks = ["canc_enrl", "canc_pay_percentage", "canc_date", "adj_salary", "adj_payments"];
                var hasCanc = false;
                var counter = 0;

                _.forEach(checks, function (check) {
                    var value = _.get(contract, check);
                    if (value || value === 0) {
                        counter++;
                    }
                });

                if (counter === checks.length) {
                    hasCanc = true;
                }

                if (contract.is_completed && hasCanc) {
                    return false;
                }

                return true;
            };

            contractCtrl.cancelContract = function (contract) {
                $uibModal
                    .open({
                        templateUrl: "admin/semesters/contracts/contract/cancel/contract-cancel.tmpl.html",
                        controller: "CancelContractCtrl as cancelContractCtrl",
                        size: "lg",
                        resolve: {
                            contract: function () {
                                return contract;
                            },
                        },
                    })
                    .result.then(
                        function (contract) {
                            Loader.showLoading("Sending Cancellation Payment");
                            ContractsModel.cancelContract(contract)
                                .then(
                                    function (updatedContract) {
                                        delete contractCtrl.contract.originalContract;
                                        delete contractCtrl.contract.revisions;

                                        _.merge(contractCtrl.contract, updatedContract);

                                        contractCtrl.contract.editable = ContractsFactory.getEditable(
                                            contractCtrl.contract
                                        );

                                        $scope.$emit("contracts.update");

                                        $window.scrollTo(0, 0);
                                    },
                                    function (result) {
                                        if (result.errors) {
                                            AlertFactory.show(
                                                _(result.errors).values().flatten().value().join("\r\n"),
                                                "danger"
                                            );
                                        }
                                    }
                                )
                                .finally(function () {
                                    Loader.hideLoading();
                                });
                        },
                        function () {}
                    );
            };

            /**
             * Notes
             */

            contractCtrl.showCreateNote = function () {
                contractCtrl.note = "";
                contractCtrl.isCreatingNote = true;
                contractCtrl.settings.isNotesOpen = true;
            };

            contractCtrl.createNote = function (contractId, noteableType, note) {
                Loader.showLoading("Creating Note");
                NotesModel.createNote(contractId, noteableType, note)
                    .then(
                        function (newNote) {
                            contract.updated_at = newNote.noteable.updated_at;
                            contract.notes.unshift(newNote);
                            contract.has_notes = true;
                            contractCtrl.isCreatingNote = false;
                            contractCtrl.settings.isNotesOpen = true;
                            $scope.$emit("contracts.update", contract);
                        },
                        function (result) {
                            AlertFactory.show(result.errors, "danger");
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            contractCtrl.deleteNote = function (contract, note) {
                NotesFactory.deleteNote(contract, note)
                    .then(function (deletedNote) {
                        contract.updated_at = deletedNote.noteable.updated_at;
                        _.remove(contract.notes, function (contractNote) {
                            return contractNote.id == deletedNote.id;
                        });
                        contract.has_notes = contract.notes.length > 0;
                        $scope.$emit("contracts.update", contract);
                    })
                    .catch(function () {});
            };

            /**
             * Custom Attributes
             */

            contractCtrl.hasAdditionalDates = function (contract) {
                if (!contract) {
                    return false;
                }

                if (
                    contract.lesson_start_date_two ||
                    contract.lesson_start_date_three ||
                    contract.lesson_start_date_four ||
                    contract.lesson_start_date_five
                ) {
                    return true;
                }

                return false;
            };

            contractCtrl.calculateSalary = function (contract) {
                contract.editable.salary.value = ContractsFactory.calculateSalary(contract);
            };

            contractCtrl.calculateAdjSalary = function (contract) {
                contract.editable.adj_salary.value = ContractsFactory.calculateAdjSalary(contract);
            };

            contractCtrl.getMonthly = function (contract, field) {
                var monthly = ContractsFactory.calculateMonthly(contract);
                contract.editable[field].value = monthly;
            };

            /**
             * Predefined Fields
             */

            function setPredefinedField(field) {
                ContractsFactory.getPredefinedField(field, semester).then(function (items) {
                    contractCtrl[field + "_options"] = items;
                });
            }

            function setInstructorPredefinedField(field) {
                InstructorsFactory.getPredefinedField(field).then(function (items) {
                    contractCtrl["instructor_" + field + "_options"] = items;
                });
            }

            _.forEach(ContractsModel.predefinedFields(), function (field) {
                setPredefinedField(field);
            });

            _.forEach(InstructorsModel.predefinedFields(), function (field) {
                setInstructorPredefinedField(field);
            });

            contractCtrl.updatePredefinedField = function (field, semester) {
                ContractsFactory.updatePredefinedField(field, semester).then(function (isUpdated) {
                    if (isUpdated) {
                        setPredefinedField(field);
                    }
                });
            };

            contractCtrl.updateInstructorPredefinedField = function (field) {
                InstructorsFactory.updatePredefinedField(field).then(function (isUpdated) {
                    if (isUpdated) {
                        setInstructorPredefinedField(field);
                    }
                });
            };

            CoursesModel.getCourse(contractId).then(function (courses) {
                courses = _(courses)
                    .filter(function (course) {
                        return (
                            contract.subject == course.subject &&
                            contract.course_catalog == course.course_catalog &&
                            contract.section_no == course.section_no &&
                            contract.parent_id != course.parent_id &&
                            course.course_prep_fee_amount > 0
                        );
                    })
                    .value();

                contractCtrl.existingCourseWithPrepFee = courses.length ? courses[0] : null;
                contractCtrl.isCoursePrepFeeEligible = courses.length === 0;
            });

            ContractsModel.getAudits(contract.parent_id, { all: true }).then(function (result) {
                contractCtrl.audits = AuditsFactory.getLatestFieldChanges(result.data, ContractsFactory);
            });

            contractCtrl.selectOptions = ContractsModel.selectOptions();
            contractCtrl.selectOptionsNullable = ContractsModel.selectOptions(true);
            contractCtrl.lessonStatuses = ContractsModel.lessonStatuses();
            contractCtrl.getProratedSalaryEnrl = ContractsFactory.getProratedSalaryEnrl;
            contractCtrl.calculateProratedSalary = ContractsFactory.calculateProratedSalary;
            contractCtrl.calculateCancSalary = ContractsFactory.calculateCancSalary;
            contractCtrl.calculateMonthly = ContractsFactory.calculateMonthly;
            contractCtrl.calculateHourlyRate = ContractsFactory.calculateHourlyRate;
            contractCtrl.calculateAdjMonthly = ContractsFactory.calculateAdjMonthly;
            contractCtrl.calculateAdjHourlyRate = ContractsFactory.calculateAdjHourlyRate;
        },
    ]);
