var directives = angular.module("app.directives", []);

directives.directive("fixHead", fixHead);

fixHead.$inject = ["$compile", "$window", "$timeout"];

function fixHead($compile, $window, $timeout) {
    var window = angular.element($window);

    return {
        restrict: "A",
        compile: function (tElement, tAttrs) {
            var table = {
                clone: tElement.parent().clone().empty(),
                original: tElement.parent(),
            };

            var header = {
                clone: tElement.clone(),
                original: tElement,
            };

            // prevent recursive compilation
            header.clone
                .removeAttr("fix-head")
                .removeAttr("fix-head-limit")
                .removeAttr("fix-head-sort")
                .removeAttr("fix-head-cols")
                .removeAttr("fix-head-update")
                .removeAttr("ng-if");

            table.clone
                .css({
                    display: "block",
                    position: "fixed",
                    top: 0,
                    left: "-9999px",
                    overflow: "hidden",
                    zIndex: 2,
                })
                .addClass("clone");

            return function postLink(scope, element, attrs) {
                var scrollContainer = element.parent().parent();

                var isCloneHidden = false;

                table.original = element.parent();
                header.orginal = element;

                // insert the element so when it is compiled it will link
                // with the correct scope and controllers
                header.original.after(header.clone);

                header.clone.css("transition", "transform 0.2s linear");

                $compile(table.clone)(scope);
                $compile(header.clone)(scope);

                $timeout(function () {
                    scrollContainer.prepend(table.clone.append(header.clone));
                });

                function tableDataLoaded() {
                    // first cell in the tbody exists when data is loaded but doesn't have a width
                    // until after the table is transformed
                    var firstCell = element.parent()[0].querySelector("tbody tr:first-child td:first-child");
                    return firstCell && !firstCell.style.width;
                }

                function bindFixedToHeader() {
                    isCloneHidden = false;
                    table.clone[0].style.left = scrollContainer[0].style.left;
                }

                function unBindFixedToHeader() {
                    isCloneHidden = true;
                    table.clone[0].style.left = "-9999px";
                }

                function resizeTable() {
                    table.clone[0].style.width = scrollContainer[0].clientWidth + "px";
                }

                function setFixedToHeader() {
                    var offset = $window.pageYOffset;
                    var tableOffsetTop = table.original[0].offsetTop;

                    if (!isCloneHidden && offset < tableOffsetTop) {
                        unBindFixedToHeader();
                    } else if (isCloneHidden && offset > tableOffsetTop) {
                        bindFixedToHeader();
                    }
                }

                function transformTable() {
                    // wrap in $timeout to give table a chance to finish rendering
                    $timeout(function () {
                        angular.forEach(element.children().children(), function (thElem, i) {
                            var columnWidth = angular.element(thElem)[0].clientWidth;

                            if (thElem) {
                                header.clone.children().children().eq(i)[0].style.minWidth = columnWidth + "px";
                            }
                        });

                        resizeTable();
                        setFixedToHeader();
                    });
                }

                // wait for data to load and then transform the table
                scope.$watch(tableDataLoaded, function (isTableDataLoaded) {
                    if (isTableDataLoaded) {
                        transformTable();
                    }
                });

                scope.$watch(attrs.fixHeadLimit, function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        transformTable();
                    }
                });

                scope.$watch(attrs.fixHeadSort, function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        var offset = $window.pageYOffset;
                        var tableOffsetTop = table.original[0].offsetTop;

                        if (offset >= tableOffsetTop) {
                            $window.scrollTo(0, tableOffsetTop);
                        }

                        transformTable();
                    }
                });

                scope.$watch(attrs.fixHeadCols, function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        transformTable();
                    }
                });

                scope.$watch(attrs.fixHeadUpdate, function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        transformTable();
                    }
                });

                var scrollXHandler = _.throttle(function () {
                    header.clone.css("transform", "translate3d(" + -scrollContainer.prop("scrollLeft") + "px, 0, 0)");
                }, 100);

                var scrollYHandler = _.throttle(function () {
                    setFixedToHeader();
                }, 100);

                var resizeHandler = _.debounce(function () {
                    transformTable();
                }, 100);

                scrollContainer.on("scroll", scrollXHandler);
                window.on("scroll", scrollYHandler);
                window.on("resize", resizeHandler);

                // Clean up when the scope is destroyed
                scope.$on("$destroy", function () {
                    scrollContainer.off("scroll", scrollXHandler);
                    window.off("scroll", scrollYHandler);
                    window.off("resize", resizeHandler);
                });
            };
        },
    };
}

app.directive("affixTabs", function ($window, $timeout, $parse) {
    return {
        restrict: "A",
        scope: false,
        link: function (scope, element, attrs) {
            var $win = angular.element($window);
            var getActiveTabIndex = attrs.affixTabsActive ? $parse(attrs.affixTabsActive) : null;
            var setActiveTabIndex = getActiveTabIndex && getActiveTabIndex.assign;
            var shouldSyncTabOnScroll = attrs.hasOwnProperty("affixTabsScroll");

            var tabHeader;
            var isAffixed = false;
            var placeholder = angular.element("<ul></ul>");
            var headings = [];
            var sections = [];

            var scrollHandler = _.throttle(function () {
                affixHeader();
                updateActiveTabOnScroll();
            });

            var resizeHandler = _.debounce(function () {
                if (isAffixed) {
                    tabHeader.css({
                        position: "",
                        top: "",
                        width: "",
                        zIndex: "",
                        background: "",
                    });
                    placeholder.remove();
                    isAffixed = false;
                }

                affixHeader();
            }, 100);

            if (shouldSyncTabOnScroll && setActiveTabIndex) {
                setActiveTabIndex(scope, 0);
            }

            function collectTabs() {
                headings = Array.from(element[0].querySelectorAll("[affix-tab-heading]")).map(function (el) {
                    return {
                        el: el,
                        name: el.getAttribute("affix-tab-heading"),
                    };
                });

                sections = Array.from(element[0].querySelectorAll("[affix-tab]")).map(function (el) {
                    return {
                        el: el,
                        name: el.getAttribute("affix-tab"),
                    };
                });
            }

            function affixHeader() {
                var scrollTop = $win.scrollTop();
                var offsetTop = element[0].offsetTop;

                if (scrollTop >= offsetTop && !isAffixed) {
                    placeholder.css({
                        height: tabHeader[0].offsetHeight + "px",
                        display: "block",
                    });

                    tabHeader.css({
                        position: "fixed",
                        top: "0",
                        width: tabHeader[0].offsetWidth + "px",
                        zIndex: 1000,
                        background: "#fff",
                    });

                    tabHeader.after(placeholder);
                    isAffixed = true;
                } else if (scrollTop < offsetTop && isAffixed) {
                    tabHeader.css({
                        position: "",
                        top: "",
                        width: "",
                        zIndex: "",
                        background: "",
                    });

                    placeholder.remove();
                    isAffixed = false;
                }
            }

            function updateActiveTabOnScroll() {
                if (!shouldSyncTabOnScroll) return;

                var scrollTop = $win.scrollTop();
                var headerHeight = isAffixed ? tabHeader[0].offsetHeight : 0;

                function matchTabByName(name) {
                    return function (h) {
                        return h.name === name;
                    };
                }

                function activateTab(tabIndex) {
                    scope.$applyAsync(function () {
                        if (setActiveTabIndex) {
                            setActiveTabIndex(scope, tabIndex);
                        }

                        $timeout(function () {
                            var heading = headings[tabIndex];
                            if (heading && heading.el) {
                                var anchor = heading.el.querySelector("a");
                                if (anchor && anchor.offsetParent !== null) {
                                    anchor.focus();
                                }
                            }
                        });
                    });
                }

                for (var i = sections.length - 1; i >= 0; i--) {
                    var sectionTop = sections[i].el.offsetTop;
                    if (scrollTop >= sectionTop - headerHeight - 1) {
                        var tabName = sections[i].name;
                        var tabIndex = headings.findIndex(matchTabByName(tabName));

                        if (tabIndex !== -1 && getActiveTabIndex && getActiveTabIndex(scope) !== tabIndex) {
                            activateTab(tabIndex);
                        }

                        break;
                    }
                }
            }

            function scrollToTabContent(tabName) {
                var section = sections.find(function (s) {
                    return s.name === tabName;
                });

                if (section && section.el) {
                    var offset = section.el.offsetTop;
                    var headerHeight = tabHeader[0].offsetHeight || 0;

                    var shouldScroll = $win.scrollTop() > element[0].offsetTop;

                    if (shouldScroll || shouldSyncTabOnScroll) {
                        $window.scrollTo(0, offset - headerHeight);
                    }
                }
            }

            function handleTabClicks() {
                element.on("click", ".nav-tabs li", function () {
                    var parentLi = this.closest("li");
                    var tabName = parentLi && parentLi.getAttribute("affix-tab-heading");

                    if (tabName) {
                        $timeout(function () {
                            scrollToTabContent(tabName);
                        });
                    }
                });

                scope.$on("$destroy", function () {
                    element.off("click", ".nav-tabs li");
                });
            }

            function initializeAffix() {
                $timeout(function () {
                    tabHeader = element.find("ul.nav-tabs, ul.nav-pills");

                    if (tabHeader.length) {
                        collectTabs();
                        affixHeader();
                        updateActiveTabOnScroll();
                        handleTabClicks();

                        $win.on("scroll", scrollHandler);
                        $win.on("resize", resizeHandler);
                    }
                });
            }

            initializeAffix();

            scope.$on("$destroy", function () {
                $win.off("scroll", scrollHandler);
                $win.off("resize", resizeHandler);
            });
        },
    };
});

directives.directive("affixTabsDeprecated", function ($window, $timeout) {
    return {
        restrict: "A",
        link: function (scope, element) {
            var window = angular.element($window);
            var tabHeader;
            var placeholder = angular.element("<ul></ul>");
            var originalOffset;
            var originalWidth;
            var isAffixed = false;

            var scrollHandler = _.throttle(function () {
                updateAffix();
            });

            var resizeHandler = _.debounce(function () {
                if (isAffixed) {
                    tabHeader.css({
                        position: "",
                        top: "",
                        width: "",
                        zIndex: "",
                        background: "",
                    });

                    placeholder.remove();
                    isAffixed = false;
                }

                originalOffset = tabHeader[0].offsetTop;
                originalWidth = tabHeader[0].offsetWidth;

                updateAffix();
            }, 100);

            function updateAffix() {
                var scrollTop = $window.pageYOffset;

                if (!tabHeader || !tabHeader.length) return; // Ensure tabs exist before running

                if (scrollTop >= originalOffset) {
                    if (!isAffixed) {
                        placeholder.css({
                            height: tabHeader[0].offsetHeight + "px",
                            display: "block",
                        });

                        tabHeader.css({
                            position: "fixed",
                            top: "0px",
                            width: originalWidth + "px",
                            zIndex: 1000,
                            background: "#fff",
                        });

                        tabHeader.after(placeholder);
                        isAffixed = true;
                    }
                } else {
                    if (isAffixed) {
                        tabHeader.css({
                            position: "",
                            top: "",
                            width: "",
                            zIndex: "",
                            background: "",
                        });

                        placeholder.remove();
                        isAffixed = false;
                    }
                }
            }

            function initializeAffix() {
                // Wait for tabs to be rendered
                $timeout(function () {
                    tabHeader = element.find("ul.nav-tabs, ul.nav-pills");

                    if (tabHeader.length) {
                        originalOffset = tabHeader[0].offsetTop;
                        originalWidth = tabHeader[0].offsetWidth;

                        // Attach scroll listener after confirming tabs exist
                        window.on("scroll", scrollHandler);
                        window.on("resize", resizeHandler);
                    }
                });
            }

            initializeAffix();

            // Cleanup
            scope.$on("$destroy", function () {
                window.off("scroll", scrollHandler);
                window.off("resize", resizeHandler);
            });
        },
    };
});

directives.directive("scrollToTopOnTabClick", function ($window, $timeout) {
    return {
        restrict: "A",
        link: function (scope, element) {
            var $win = angular.element($window);

            function scrollToTop() {
                $timeout(function () {
                    var tabset = element[0]; // Get the tabset element
                    if (tabset) {
                        var tabTop = tabset.offsetTop; // + $window.pageYOffset;
                        if ($win[0].pageYOffset > tabTop) $window.scrollTo(0, tabTop); // Instantly scroll to top of tabs
                    }
                }, 50);
            }

            // Attach click listener to all tab headers
            element.on("click", ".nav-tabs a", function () {
                scrollToTop();
            });

            // Cleanup event listener when directive is destroyed
            scope.$on("$destroy", function () {
                element.off("click", ".nav-tabs a");
            });
        },
    };
});

directives.directive("autofocus", [
    "$timeout",
    function ($timeout) {
        return {
            restrict: "A",
            link: function (scope, element) {
                $timeout(function () {
                    element[0].focus();
                });
            },
        };
    },
]);

directives.directive("fileModel", [
    "$parse",
    function ($parse) {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                var modelSetter = model.assign;

                element.bind("change", function () {
                    scope.$apply(function () {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            },
        };
    },
]);

directives.directive("hasRole", [
    "$auth",
    "UsersModel",
    function ($auth, UsersModel) {
        return {
            link: function (scope, element, attrs) {
                element.addClass("ng-hide");

                if (!$auth.isAuthenticated()) {
                    return false;
                }

                if (!_.isString(attrs.hasRole)) {
                    throw "hasRole value must be a string";
                }

                var roles = attrs.hasRole.split(",");
                roles = _.map(roles, function (role) {
                    return role.trim();
                });

                var currentUser = UsersModel.getCurrentUser();

                var userRoles = _.map(currentUser.roles, "name");

                var hasRole = false;

                _.forEach(roles, function (role) {
                    var notFlag = role[0] === "!";
                    if (notFlag) {
                        role = role.slice(1).trim();
                    }

                    if ((_.includes(userRoles, role) && !notFlag) || (!_.includes(userRoles, role) && notFlag)) {
                        hasRole = true;
                    }
                });

                if (hasRole) {
                    element.removeClass("ng-hide");
                } else {
                    element.addClass("ng-hide");
                }
            },
        };
    },
]);

app.directive("hasPermission", [
    "UsersFactory",
    function (UsersFactory) {
        return {
            link: function (scope, element, attrs) {
                element.addClass("ng-hide");

                if (UsersFactory.hasPermission(attrs.hasPermission)) {
                    element.removeClass("ng-hide");
                } else {
                    element.addClass("ng-hide");
                }
            },
        };
    },
]);

directives.directive("backToTop", [
    "$window",
    function ($window) {
        var template =
            '<div class="back-to-top-container"><div style="left:20px" class="back-to-top"><i class="fa fa-chevron-up"></i></div>' +
            '<div style="right:20px" class="back-to-top"><i class="fa fa-chevron-up"></i></div></div>';

        return {
            restrict: "E",
            replace: true,
            template: template,
            link: function ($scope, element, attrs) {
                $window.onscroll = function () {
                    if ($window.scrollY <= 0) {
                        element.css("display", "none");
                    } else {
                        element.css("display", "block");
                    }
                };

                element.on("click", function () {
                    $window.scrollTo(0, 0);
                });
            },
        };
    },
]);

directives.directive("serverValidation", [
    "$timeout",
    function ($timeout) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, elem, attr, ctrl) {
                // set "valid" by default on typing
                elem.bind("keyup", function () {
                    $timeout(function () {
                        ctrl.$setValidity("server", true);
                    });
                });
            },
        };
    },
]);

directives
    .directive("search", [
        "$compile",
        "$location",
        function ($compile, $location) {
            var defaultTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-search"></i></div>' +
                '<ui-select style="width:25%;float:left" ng-model="search.searchCol" theme="bootstrap">' +
                '<ui-select-match placeholder="Search field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in search.searchProps | filter:{is_hidden:0} | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search" class="ui-select-choice" ng-class="(property.group).toLowerCase()"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<input style="width:75%" type="text" class="form-control" placeholder="Search for..." ng-model="search.value" ng-change="search.update()" ng-model-options="{ debounce: 200 }">' +
                '<div class="input-group-btn">' +
                '<button class="btn btn-success" ng-click="add(1)"><span class="fa fa-plus"></span></button>' +
                '<button class="btn btn-danger" ng-click="add(0)"><span class="fa fa-minus"></span></button>' +
                "</div>" +
                "</div>" +
                "</div>";

            var rangeTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-search"></i></div>' +
                '<ui-select ng-model="search.searchCol" theme="bootstrap">' +
                '<ui-select-match placeholder="Search field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in search.searchProps | filter:{is_hidden:0} | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<div class="input-group-addon"><i class="fa">&ge;</i></div>' +
                '<input type="text" class="form-control" placeholder="Search for..." ng-model="search.searchGT" ng-model-options="{ debounce: 200 }">' +
                '<div class="input-group-addon"><i class="fa">&le;</i></div>' +
                '<input type="text" class="form-control" placeholder="Search for..." ng-model="search.searchLT" ng-model-options="{ debounce: 200 }">' +
                '<div class="input-group-btn">' +
                '<button class="btn btn-success" ng-click="add(1)"><span class="fa fa-plus"></span></button>' +
                '<button class="btn btn-danger" ng-click="add(0)"><span class="fa fa-minus"></span></button>' +
                "</div>" +
                "</div>" +
                "</div>";

            var dateRangeTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-search"></i></div>' +
                '<ui-select ng-model="search.searchCol" theme="bootstrap">' +
                '<ui-select-match placeholder="Search field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in search.searchProps | filter:{is_hidden:0} | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<div class="input-group-addon"><i class="fa">&ge;</i></div>' +
                '<input type="text" ng-model="search.searchGT" class="form-control" uib-datepicker-popup="M/d/yy" is-open="dateGTOpen" ng-focus="dateGTOpen=true" ng-readonly="true" placeholder="Date">' +
                '<div class="input-group-addon"><i class="fa">&le;</i></div>' +
                '<input type="text" ng-model="search.searchLT" class="form-control" uib-datepicker-popup="M/d/yy" is-open="dateLTOpen" ng-focus="dateLTOpen=true" ng-readonly="true" placeholder="Date">' +
                '<div class="input-group-btn">' +
                '<button class="btn btn-success" ng-click="add(1)"><span class="fa fa-plus"></span></button>' +
                '<button class="btn btn-danger" ng-click="add(0)"><span class="fa fa-minus"></span></button>' +
                "</div>" +
                "</div>" +
                "</div>";

            var dateTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-search"></i></div>' +
                '<ui-select style="width:25%;float:left" ng-model="search.searchCol" theme="bootstrap">' +
                '<ui-select-match placeholder="Search field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in search.searchProps | filter:{is_hidden:0} | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<input style="width:75%" type="text" ng-model="search.value" class="form-control" uib-datepicker-popup="M/d/yy" is-open="dateOpen" ng-focus="dateOpen=true" ng-readonly="true" placeholder="Date">' +
                '<div class="input-group-btn">' +
                '<button class="btn btn-success" ng-click="add(1)"><span class="fa fa-plus"></span></button>' +
                '<button class="btn btn-danger" ng-click="add(0)"><span class="fa fa-minus"></span></button>' +
                "</div>" +
                "</div>" +
                "</div>";

            var timeTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-search"></i></div>' +
                '<ui-select style="width:25%;float:left" ng-model="search.searchCol" theme="bootstrap">' +
                '<ui-select-match placeholder="Search field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in search.searchProps | filter:{is_hidden:0} | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<div style="width:75%;float:left" uib-timepicker ng-model="search.value" show-spinners="false" show-meridian="true"></div>' +
                '<div class="input-group-btn">' +
                '<button class="btn btn-success" ng-click="add(1)"><span class="fa fa-plus"></span></button>' +
                '<button class="btn btn-danger" ng-click="add(0)"><span class="fa fa-minus"></span></button>' +
                "</div>" +
                "</div>" +
                "</div>";

            var booleanTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-search"></i></div>' +
                '<ui-select style="width:25%;float:left" ng-model="search.searchCol" theme="bootstrap">' +
                '<ui-select-match placeholder="Search field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in search.searchProps | filter:{is_hidden:0} | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<select style="width:75%" ng-model="search.value" class="form-control" ng-options="o.v as o.n for o in [{ n: \'Pending\', v: null }, { n: \'No\', v: false }, { n: \'Yes\', v: true }]">' +
                "</select>" +
                '<div class="input-group-btn">' +
                '<button class="btn btn-success" ng-click="add(1)"><span class="fa fa-plus"></span></button>' +
                '<button class="btn btn-danger" ng-click="add(0)"><span class="fa fa-minus"></span></button>' +
                "</div>" +
                "</div>" +
                "</div>";

            var statusTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-search"></i></div>' +
                '<ui-select style="width:25%;float:left" ng-model="search.searchCol" theme="bootstrap">' +
                '<ui-select-match placeholder="Search field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in search.searchProps | filter:{is_hidden:0} | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<div style="width:75%" class="btn-group" role="group">' +
                '<button style="width:33.3333%" ng-class="{' +
                "'active':" +
                '(search.value===true)}" type="button" class="btn btn-default" ng-click="search.value=true"><span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span></button>' +
                '<button style="width:33.3333%" ng-class="{' +
                "'active':" +
                '(search.value===null)}" type="button" class="btn btn-default" ng-click="search.value=null"><span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span></button>' +
                '<button style="width:33.3333%" ng-class="{' +
                "'active':" +
                '(search.value===false)}" type="button" class="btn btn-default" ng-click="search.value=false"><span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span></button>' +
                "</div>" +
                '<div class="input-group-btn"><button class="btn btn-success" ng-click="add(1)"><span class="fa fa-plus"></span></button></div>' +
                "</div>" +
                "</div>";

            return {
                restrict: "E",
                scope: {
                    search: "=",
                },
                template: defaultTemplate,
                link: function (scope, element, attrs) {
                    var cloneScope = null;
                    var cloneElement = null;

                    var dates = scope.search.dates;
                    var times = scope.search.times;
                    var numbers = scope.search.numbers;
                    var booleans = scope.search.booleans;
                    var statuses = scope.search.statuses;
                    var partialMatches = scope.search.partialMatches ? scope.search.partialMatches : [];

                    function updateTemplate(template) {
                        if (cloneElement) {
                            cloneScope.$destroy();
                            cloneScope = null;
                            cloneElement = null;
                        }

                        element.html(template);
                        cloneScope = scope.$new();
                        cloneElement = $compile(element.contents())(cloneScope);
                    }

                    scope.updateSearchCol = function () {
                        scope.search.value = "";
                        scope.search.searchGT = "";
                        scope.search.searchLT = "";
                        template = defaultTemplate;

                        if (_.includes(dates, scope.search.searchCol.value)) {
                            template = dateTemplate;
                        } else if (_.includes(times, scope.search.searchCol.value)) {
                            template = timeTemplate;
                        } else if (_.includes(booleans, scope.search.searchCol.value)) {
                            template = booleanTemplate;
                            scope.search.value = true;
                        } else if (_.includes(statuses, scope.search.searchCol.value)) {
                            template = statusTemplate;
                        }

                        if (scope.search.searchCol.is_range && _.includes(dates, scope.search.searchCol.value)) {
                            template = dateRangeTemplate;
                        } else if (scope.search.searchCol.is_range) {
                            template = rangeTemplate;
                        }

                        template +=
                            '<div style="display:inline-block" class="mr mb" ng-repeat="filter in search.filters track by $index" ng-class="{\'bg-danger\':!filter.is_included,\'bg-success\':filter.is_included}">' +
                            '<button class="btn btn-sm btn-danger" ng-click="remove(filter)">&times;</button><span class="pl pr" ng-bind-html="filter.html"></span>' +
                            "</div>";

                        updateTemplate(template);
                    };

                    scope.updateSearchCol();

                    scope.$watchCollection("search.filters", function (filters) {
                        updateQueryString();
                    });

                    scope.$watchCollection("search.searchCol", function (filters) {
                        scope.updateSearchCol();
                    });

                    function updateQueryString() {
                        var search = [];
                        _.forEach(scope.search.filters, function (filter) {
                            var data = {
                                c: filter.searchCol,
                                v: filter.value,
                                g: filter.searchGT,
                                l: filter.searchLT,
                                i: filter.is_included,
                            };
                            search.push(angular.toJson(data));
                        });
                        $location.search("s", search);
                    }

                    scope.add = function (is_included) {
                        scope.search.limitTo = 100;

                        var filter = {};
                        var comparator = true;
                        filter.expression = scope.search.get();
                        filter.searchCol = scope.search.searchCol;
                        filter.value = scope.search.value;
                        filter.searchGT = scope.search.searchGT;
                        filter.searchLT = scope.search.searchLT;
                        filter.is_included = is_included;

                        if (filter.value && _.includes(dates, scope.search.searchCol.value)) {
                            filter.comparator = scope.search.dateComparator;
                        }

                        if (_.includes(dates, scope.search.searchCol.value)) {
                            comparator = scope.search.dateComparator;
                        } else if (_.includes(times, scope.search.searchCol.value)) {
                            comparator = scope.search.timeComparator;
                        } else if (_.includes(partialMatches, scope.search.searchCol.value)) {
                            comparator = false;
                        } else if (_.includes(numbers, scope.search.searchCol.value)) {
                            comparator = scope.search.numberComparator;
                        } else if (scope.search.searchCol.value) {
                            comparator = scope.search.stringComparator;
                        } else if (!scope.search.searchCol.value) {
                            comparator = false;
                        }

                        filter.comparator = comparator;

                        if (_.includes(statuses, scope.search.searchCol.value)) {
                            switch (scope.search.value) {
                                case 3:
                                    filter.searchVal =
                                        '<span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>';
                                    break;
                                case 2:
                                    filter.searchVal =
                                        '<span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span>';
                                    break;
                                case 1:
                                    filter.searchVal =
                                        '<span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>';
                                    break;
                            }
                        } else {
                            filter.searchVal = scope.search.format(scope.search.searchCol.value, scope.search.value);
                        }

                        if (scope.search.searchCol.is_range) {
                            var searchGT = scope.search.searchGT;
                            var searchLT = scope.search.searchLT;

                            if (_.includes(dates, scope.search.searchCol.value)) {
                                searchGT = scope.search.format(
                                    scope.search.searchCol.value,
                                    new Date(searchGT).setHours(0, 0, 0, 0)
                                );
                                searchLT = scope.search.format(
                                    scope.search.searchCol.value,
                                    new Date(searchLT).setHours(0, 0, 0, 0)
                                );
                            }

                            filter.searchVal = "";

                            if (scope.search.searchGT) {
                                filter.searchVal += ">= " + searchGT;
                            }

                            if (scope.search.searchGT && scope.search.searchLT) {
                                filter.searchVal += ", ";
                            }

                            if (scope.search.searchLT) {
                                filter.searchVal += "<= " + searchLT;
                            }
                        }

                        filter.html = filter.searchCol.label + ": " + filter.searchVal;

                        if (!is_included) {
                            scope.search.value =
                                "!" +
                                (scope.search.value ||
                                scope.search.value === false ||
                                scope.search.value === 0 ||
                                !filter.searchCol.value
                                    ? scope.search.value
                                    : "!");
                            filter.expression = scope.search.get();
                        }

                        scope.search.filters.push(filter);

                        if (scope.search.searchCol.value && scope.search.searchCol.value != "id") {
                            var searchProp = _.find(scope.search.searchProps, function (searchProp) {
                                return searchProp.label == scope.search.searchCol.label;
                            });
                            searchProp.is_hidden = 1;
                            scope.search.searchCols.push(scope.search.searchCol);
                        }

                        scope.search.searchCol = scope.search.searchProps[0];

                        scope.updateSearchCol();

                        updateQueryString();
                    };

                    scope.remove = function (filter) {
                        scope.search.limitTo = 100;

                        if (filter.searchCol.value) {
                            var searchProp = _.find(scope.search.searchProps, function (searchProp) {
                                return searchProp.label == filter.searchCol.label;
                            });
                            searchProp.is_hidden = 0;
                        }

                        _.remove(scope.search.filters, function (_filter) {
                            return _filter.expression == filter.expression && _filter.is_hidden == filter.is_hidden;
                        });

                        _.remove(scope.search.searchCols, function (searchCol) {
                            return searchCol.label == filter.searchCol.label;
                        });

                        updateQueryString();
                    };

                    function add(s) {
                        s = angular.fromJson(s);
                        scope.search.searchCol = s.c;
                        scope.search.value = s.v;
                        scope.search.searchGT = s.g;
                        scope.search.searchLT = s.l;
                        scope.add(s.i);
                    }

                    if ($location.search().s) {
                        if (angular.isArray($location.search().s)) {
                            _.forEach($location.search().s, function (s) {
                                add(s);
                            });
                        } else {
                            add($location.search().s);
                        }
                    }
                },
            };
        },
    ])
    .directive("replace", [
        "$timeout",
        "$compile",
        "$injector",
        function ($timeout, $compile, $injector) {
            var defaultTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-exchange"></i></div>' +
                '<ui-select style="width:25%;float:left" ng-model="replacement.field" theme="bootstrap" on-select="updateSearchCol()" uis-open="search.value=\'\'">' +
                '<ui-select-match placeholder="Replace field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in fields | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<input style="width:75%" type="text" class="form-control" placeholder="Replace with..." ng-model="replacement.value" ng-disabled="!replacement.field" ng-model-options="{ debounce: 200 }">' +
                '<div class="input-group-btn">' +
                '<button class="btn btn-warning" ng-click="replace({replacement:replacement, isClearing:false})" ng-disabled="!replacement.field || (!replacement.value && replacement.value !== 0)">Replace</button>' +
                '<button class="btn btn-default" ng-disabled="!replacement.field" ng-click="clear()" title="Clear the value of the selected field">Clear</button>' +
                "</div>" +
                "</div>" +
                "</div>";

            var dateTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-exchange"></i></div>' +
                '<ui-select style="width:25%;float:left" ng-model="replacement.field" theme="bootstrap" on-select="updateSearchCol()">' +
                '<ui-select-match placeholder="Replace field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in fields | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<input style="width:75%" type="text" ng-model="replacement.value" class="form-control" uib-datepicker-popup="M/d/yy" is-open="dateOpen" ng-focus="dateOpen=true" ng-readonly="true" placeholder="Date">' +
                '<div class="input-group-btn">' +
                '<button class="btn btn-warning" ng-click="replace({replacement:replacement, isClearing:false})" ng-disabled="!replacement.field || !replacement.value">Replace</button>' +
                '<button class="btn btn-default" ng-disabled="!replacement.field" ng-click="clear()" title="Clear the value of the selected field">Clear</button>' +
                "</div>" +
                "</div>" +
                "</div>";

            var booleanTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-exchange"></i></div>' +
                '<ui-select style="width:25%;float:left" ng-model="replacement.field" theme="bootstrap" on-select="updateSearchCol()">' +
                '<ui-select-match placeholder="Replace field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in fields | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<select style="width:75%" ng-model="replacement.value" class="form-control" ng-options="property.value as property.label for property in selectOptions">' +
                "</select>" +
                '<div class="input-group-btn">' +
                '<button class="btn btn-warning" ng-click="replace({replacement:replacement, isClearing:false})" ng-disabled="!replacement.field || !replacement.value && replacement.value!==false">Replace</button>' +
                '<button class="btn btn-default" ng-disabled="!replacement.field" ng-click="clear()" title="Clear the value of the selected field">Clear</button>' +
                "</div>" +
                "</div>" +
                "</div>";

            var numberTemplate =
                '<div class="form-group">' +
                '<div class="input-group">' +
                '<div class="input-group-addon"><i class="fa fa-exchange"></i></div>' +
                '<ui-select style="width:25%;float:left" ng-model="replacement.field" theme="bootstrap" on-select="updateSearchCol()">' +
                '<ui-select-match placeholder="Replace field">{{$select.selected.label}}</ui-select-match>' +
                '<ui-select-choices group-by="\'group\'" repeat="property in fields | filter: $select.search">' +
                '<div ng-bind-html="property.label | highlight: $select.search"></div>' +
                "</ui-select-choices>" +
                "</ui-select>" +
                '<input style="width:75%" type="number" class="form-control" placeholder="Replace with..." ng-model="replacement.value" ng-disabled="!replacement.field">' +
                '<div class="input-group-btn">' +
                '<button class="btn btn-warning" ng-click="replace({replacement:replacement, isClearing:false})" ng-disabled="!replacement.field || (!replacement.value && replacement.value !== 0)">Replace</button>' +
                '<button class="btn btn-default" ng-disabled="!replacement.field" ng-click="clear()" title="Clear the value of the selected field">Clear</button>' +
                "</div>" +
                "</div>" +
                "</div>";

            return {
                restrict: "E",
                scope: {
                    replacement: "=replacement",
                    replace: "&replace",
                    model: "@",
                    semester: "=semester",
                },
                template: defaultTemplate,
                link: function (scope, element, attrs) {
                    model = $injector.get(scope.model);

                    var cloneScope = null;
                    var cloneElement = null;

                    var fillable = model.fillable(scope.semester);
                    var labels = model.labels(scope.semester);
                    var groups = model.groups();
                    var protected = model.protected();

                    _.remove(fillable, function (field) {
                        return _.includes(protected, field);
                    });

                    scope.selectOptions = [
                        {
                            label: "Yes",
                            value: true,
                        },
                        {
                            label: "No",
                            value: false,
                        },
                    ];

                    scope.fields = [];

                    _.forEach(fillable, function (field) {
                        scope.fields.push({
                            value: field,
                            label: labels[field],
                            group: groups[field] ? groups[field] : "",
                        });
                    });

                    function updateTemplate(template) {
                        $timeout(function () {
                            if (cloneElement) {
                                cloneScope.$destroy();
                                cloneElement.remove();
                                cloneScope = null;
                                cloneElement = null;
                            }

                            element.html(template);
                            cloneScope = scope.$new();
                            cloneElement = $compile(element.contents())(cloneScope);
                        });
                    }

                    scope.updateSearchCol = function () {
                        var template = defaultTemplate;
                        scope.replacement.value = "";

                        if (_.includes(model.statuses(), scope.replacement.field.value)) {
                            template = statusTemplate;
                        }

                        if (_.includes(model.numbers(), scope.replacement.field.value)) {
                            template = numberTemplate;
                        }
                        if (_.includes(model.dates(), scope.replacement.field.value)) {
                            template = dateTemplate;
                        }

                        if (_.includes(model.booleans(), scope.replacement.field.value)) {
                            template = booleanTemplate;
                        }

                        updateTemplate(template);
                    };

                    scope.clear = function () {
                        scope.replacement.value = "";
                        scope.replace({
                            replacement: scope.replacement,
                            isClearing: true,
                        });
                    };

                    scope.replacement = {};
                    scope.replacement.value = "";
                },
            };
        },
    ])
    .directive("auditSummary", function ($filter) {
        return {
            restrict: "E",
            scope: {
                audits: "=",
                field: "@",
            },
            template:
                '<small class="text-muted">' +
                '<span ng-if="audits && audits[field]">' +
                "Updated {{ audits[field].formattedDate }}: " +
                '<span class="text-danger" title="Old value for {{ audits[field].label }}">{{ audits[field].old }}</span>' +
                "</span>" +
                '<span ng-if="audits && !audits[field]">' +
                "N/A" +
                "</span>" +
                '<span ng-if="!audits">' +
                '<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>' +
                "</span>" +
                "</small>",
        };
    });
