angular.module("app.factories.notes", []).factory("NotesFactory", [
    "BASE_URL",
    "$q",
    "Loader",
    "$location",
    "AlertFactory",
    "$uibModal",
    "NotesModel",
    function (BASE_URL, $q, Loader, $location, AlertFactory, $uibModal, NotesModel) {
        var notesFactory = this;

        function buildNameCourseDescription(fullName, course) {
            return fullName + (fullName && course ? " - " : "") + course;
        }

        function getUrl(noteable, noteableType) {
            switch (noteableType) {
                case "contract":
                    return BASE_URL + "#!/admin/semesters/" + noteable.semester_id + "/contracts/" + noteable.parent_id;
                case "warning":
                    return (
                        BASE_URL +
                        "#!/admin/semesters/" +
                        noteable.contract.semester_id +
                        "/contracts/" +
                        noteable.contract_id
                    );
                case "instructor":
                    return BASE_URL + "#!/admin/instructors/" + noteable.user_id;
                default:
                    return null;
            }
        }

        function getDescription(noteable, noteableType) {
            switch (noteableType) {
                case "contract":
                    return buildNameCourseDescription(noteable.full_name, noteable.course);
                case "warning":
                    return buildNameCourseDescription(noteable.contract.full_name, noteable.contract.course);
                case "instructor":
                    return noteable.user.full_name;
                default:
                    return null;
            }
        }

        function getLink(noteable, noteableType) {
            var url = getUrl(noteable, noteableType);
            var description = getDescription(noteable, noteableType);

            if (url && description) {
                return '<a href="' + url + '">' + description + "</a>";
            }

            return null;
        }

        function showNotes(noteable, noteableType) {
            $location.search("notes", noteable.id);

            var modalInstance = $uibModal.open({
                templateUrl: "admin/notes/notes.tmpl.html",
                controller: "NotesCtrl as notesCtrl",
                windowClass: "modal-notes",
                resolve: {
                    noteable: function () {
                        return noteable;
                    },
                    noteableType: function () {
                        return noteableType;
                    },
                    link: function () {
                        return getLink(noteable, noteableType);
                    },
                },
            });

            modalInstance.result.catch(function () {});
        }

        function deleteNote(noteable, note) {
            var deferred = $q.defer();

            var modalInstance = $uibModal.open({
                templateUrl: "admin/notes/delete/note-delete.tmpl.html",
                controller: "DeleteNoteCtrl as deleteNoteCtrl",
                resolve: {
                    note: function () {
                        return note;
                    },
                    link: function () {
                        return getLink(noteable, note.noteable_type.replace("App\\", "").toLowerCase());
                    },
                },
            });

            modalInstance.result
                .then(function (noteId) {
                    Loader.showLoading("Deleting Note");
                    NotesModel.deleteNote(noteId)
                        .then(
                            function (note) {
                                deferred.resolve(note);
                            },
                            function (result) {
                                AlertFactory.show(result.errors, "danger");
                                deferred.reject();
                            }
                        )
                        .finally(function () {
                            Loader.hideLoading();
                        });
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        notesFactory.showNotes = showNotes;
        notesFactory.deleteNote = deleteNote;

        return notesFactory;
    },
]);
