angular
    .module("admin.instructors.instructor.contracts.contract", [
        "admin.instructors.instructor.contracts.contract.instructor",
        "admin.instructors.instructor.contracts.contract.courses",
        "admin.instructors.instructor.contracts.contract.revisions",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.instructor.contracts.contract", {
            url: "/{contractId:int}",
            views: {
                "": {
                    templateUrl: "admin/instructors/instructor/contracts/contract/contract.tmpl.html",
                    controller: "AdminInstructorsContractCtrl as contractCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Contract",
            },
            resolve: {
                contract: [
                    "$state",
                    "$stateParams",
                    "$q",
                    "ContractsModel",
                    "Loader",
                    function ($state, $stateParams, $q, ContractsModel, Loader) {
                        var contractId = $stateParams.contractId;
                        var deferred = $q.defer();

                        Loader.showLoading("Loading Contract");
                        ContractsModel.getContract(contractId)
                            .then(
                                function (contract) {
                                    deferred.resolve(contract);
                                },
                                function () {
                                    deferred.reject();
                                    $state.go("admin.instructors.instructor.contracts");
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });

                        return deferred.promise;
                    },
                ],
            },
        });
    })
    .controller("AdminInstructorsContractCtrl", [
        "contract",
        "$scope",
        "$state",
        "$stateParams",
        "ContractsModel",
        "CoursesModel",
        "ContractsFactory",
        "AuditsFactory",
        function (
            contract,
            $scope,
            $state,
            $stateParams,
            ContractsModel,
            CoursesModel,
            ContractsFactory,
            AuditsFactory
        ) {
            var contractCtrl = this;
            var contractId = $stateParams.contractId;

            contractCtrl.contract = contract;
            contractCtrl.settings = ContractsModel.getSettings();
            ContractsFactory.watchSettings($scope, contractCtrl.settings);
            contractCtrl.hasAdditionalDates = false;

            var modified = [];

            $scope.contract = contract;

            contractCtrl.isModified = function (key) {
                return _.includes(_.keys(modified), key);
            };

            contractCtrl.getModified = function (key) {
                var value = _.find(modified, function (value, _key) {
                    return key == _key;
                });

                if (!value && value !== 0 && value !== false) {
                    return "NULL";
                }

                return value;
            };

            contractCtrl.hasAdditionalDates = function () {
                if (!contractCtrl.contract) {
                    return false;
                }

                if (
                    contractCtrl.contract.lesson_start_date_two ||
                    contractCtrl.contract.lesson_start_date_three ||
                    contractCtrl.contract.lesson_start_date_four ||
                    contractCtrl.contract.lesson_start_date_five
                ) {
                    return true;
                }

                return false;
            };

            CoursesModel.getCourse(contractId).then(function (courses) {
                courses = _(courses)
                    .filter(function (course) {
                        return (
                            contract.subject == course.subject &&
                            contract.course_catalog == course.course_catalog &&
                            contract.section_no == course.section_no &&
                            contract.parent_id != course.parent_id &&
                            course.course_prep_fee_amount > 0
                        );
                    })
                    .value();

                contractCtrl.existingCourseWithPrepFee = courses.length ? courses[0] : null;
                contractCtrl.isCoursePrepFeeEligible = courses.length === 0;
            });

            contractCtrl.showInstructorView = function () {
                $state.go("admin.instructors.instructor.contracts.contract.instructor");
            };

            ContractsModel.getAudits(contract.parent_id, { all: true }).then(function (result) {
                contractCtrl.audits = AuditsFactory.getLatestFieldChanges(result.data, ContractsFactory);
            });

            contractCtrl.calculateProratedSalary = ContractsFactory.calculateProratedSalary;
            contractCtrl.calculateCancSalary = ContractsFactory.calculateCancSalary;
            contractCtrl.calculateMonthly = ContractsFactory.calculateMonthly;
            contractCtrl.calculateHourlyRate = ContractsFactory.calculateHourlyRate;
            contractCtrl.calculateAdjMonthly = ContractsFactory.calculateAdjMonthly;
            contractCtrl.calculateAdjHourlyRate = ContractsFactory.calculateAdjHourlyRate;
        },
    ]);
