angular.module("app.factories.warnings", []).factory("WarningsFactory", [
    "$window",
    "$timeout",
    "$filter",
    "$uibModal",
    "WarningsModel",
    "ApproversModel",
    "SemestersModel",
    "ContractsModel",
    "Loader",
    "AlertFactory",
    function (
        $window,
        $timeout,
        $filter,
        $uibModal,
        WarningsModel,
        ApproversModel,
        SemestersModel,
        ContractsModel,
        Loader,
        AlertFactory
    ) {
        var warningsFactory = this;

        var dates = ContractsModel.dates();
        var times = ContractsModel.times();
        var decimals = ContractsModel.decimals();

        var roles = ApproversModel.roles();
        _.remove(roles, { name: "provost" });
        _.remove(roles, { name: "chancellor" });

        var getSearchProperties = function () {
            return [
                {
                    label: "All",
                    value: null,
                    is_hidden: 0,
                },
                {
                    label: "ID",
                    value: "id",
                    is_hidden: 0,
                    group: "Warning",
                },
                {
                    label: "Title",
                    value: "title",
                    is_hidden: 0,
                    group: "Warning",
                },
                {
                    label: "Approval Status",
                    value: "status_approval",
                    is_hidden: 0,
                    group: "Status",
                },
                {
                    label: "Acceptance Status",
                    value: "status_acceptance",
                    is_hidden: 0,
                    group: "Status",
                },
                {
                    label: "Updated",
                    value: "max_updated_at",
                    is_hidden: 0,
                    group: "Status",
                },
            ];
        };

        var getSortProperties = function () {
            return [];
        };

        var getDates = function () {
            return ["created_at", "updated_at", "max_updated_at"];
        };

        var getNumbers = function () {
            return ["id"];
        };

        var getBooleans = function () {
            return [];
        };

        var getStatuses = function () {
            return ["status_completion", "status_approval", "status_acceptance"];
        };

        var format = function (prop, value) {
            if (_.includes(getDates(), prop)) {
                value = $filter("date")(value, "M/d/yy");
            }

            if (_.includes(getBooleans(), prop)) {
                value = value ? "Yes" : "No";
            }

            return value;
        };

        var getSearch = function () {
            var searchProps = getSearchProperties();
            var sortProps = getSortProperties();

            var search = {
                searchProps: searchProps,
                sortProps: sortProps,
                searchCol: searchProps[0],
                sortCol: sortProps[0],
                sortType: "max_updated_at",
                sortReverse: true,
                limitTo: 25,
                totalItems: 0,
                orderBy: ["!!max_updated_at", "max_updated_at"],
                value: "",
                currentPage: 1,
                dates: getDates(),
                numbers: getNumbers(),
                booleans: getBooleans(),
                statuses: getStatuses(),
                filters: [],
                searchCols: [],
                get: function () {
                    var filter = {};

                    if (this.searchCol.value) {
                        value = this.value;

                        if (_.includes(this.dates, this.searchCol.value)) {
                            value = angular.fromJson(angular.toJson(this.value));
                        }

                        if (!value && value !== 0) {
                            value = null;
                        }
                        filter = _.set(filter, this.searchCol.value, value);
                    } else {
                        filter = this.value;
                    }

                    return filter;
                },
                dateFilter: function () {
                    var filter = {};

                    if (!_.includes(this.dates, this.searchCol.value)) {
                        return filter;
                    }

                    if (this.searchCol.value) {
                        var value = this.value;
                        if (!value) {
                            value = null;
                        }
                        filter[this.searchCol.value] = value;
                    }

                    return filter;
                },
                stringComparator: function (a, b) {
                    return typeof a === "string" && typeof b === "string"
                        ? a.toUpperCase() === b.toUpperCase()
                        : angular.equals(a, b);
                },
                numberComparator: function (a, b) {
                    return a == b;
                },
                dateComparator: function (a, b) {
                    return angular.equals(new Date(a).setHours(0, 0, 0, 0), new Date(b).setHours(0, 0, 0, 0));
                },
                timeComparator: function (a, b) {
                    var dateA = new Date(a);
                    var dateB = new Date(b);

                    if (!b) {
                        return a == b;
                    }

                    return (
                        angular.equals(dateA.getHours(), dateB.getHours()) &&
                        angular.equals(dateA.getMinutes(), dateB.getMinutes())
                    );
                },
                updateSortCol: function () {
                    var searchCol = this.searchCol;
                    if (this.searchCol.value) {
                        var index = _.findIndex(this.sortProps, function (property) {
                            return property.value == searchCol.value;
                        });
                        if (index >= 0) {
                            this.sortCol = this.sortProps[index];
                        }
                    }
                    this.value = null;
                },
                pageYOffset: null,
                minLimitTo: null,
                resetLimitTo: function () {
                    if (this.limitTo > this.minLimitTo) {
                        $timeout(function () {
                            search.limitTo = search.minLimitTo || search.limitTo;
                        });
                    }
                },
                setLimitTo: function (totalItems) {
                    if (this.limitTo < totalItems) {
                        if (!this.pageYOffset) {
                            this.pageYOffset = $window.pageYOffset;
                        }

                        if (this.pageYOffset && !this.minLimitTo) {
                            this.minLimitTo = this.limitTo;
                        }

                        this.limitTo += 25;
                    }
                },
                statusOrder: function (item) {
                    var value = _.get(item, currentSortType);
                    if (value === null) return 1;
                    if (value === false) return 2;
                    if (value === true) return 3;
                    return 4;
                },
                numberOrder: function (item) {
                    return +_.get(item, currentSortType);
                },
                sort: function (sortType) {
                    this.limitTo = this.minLimitTo || this.limitTo;
                    this.sortReverse = !this.sortReverse;
                    this.sortType = sortType;

                    currentSortType = sortType;

                    if (_.includes(this.numbers, sortType)) {
                        this.orderBy = [this.numberOrder, sortType];
                    } else if (_.includes(this.statuses, sortType)) {
                        this.orderBy = [this.statusOrder, sortType];
                    } else if (sortType == "semester") {
                        this.orderBy = ["semester.year", "semester.term.weight"];
                    } else {
                        this.orderBy = sortType;
                    }

                    this.currentPage = 1;
                },
                freezeOrder: function (warnings) {
                    warnings = $filter("orderBy")(warnings, this.orderBy, this.sortReverse);

                    var i;
                    if (this.sortReverse) {
                        for (i = warnings.length; i > 0; i--) {
                            warnings[warnings.length - i].frozenOrder = i;
                        }
                    } else {
                        for (i = 0; i < warnings.length; i++) {
                            warnings[i].frozenOrder = i;
                        }
                    }
                    this.sortType = "frozenOrder";
                    this.orderBy = "frozenOrder";
                },
                format: format,
                cleanUp: function () {
                    window.off("scroll", scrollYHandler);
                },
            };

            var window = angular.element($window);

            var scrollYHandler = _.throttle(function (e) {
                if (search.pageYOffset && $window.pageYOffset + search.pageYOffset * 0.25 < search.pageYOffset) {
                    search.resetLimitTo();
                }
            }, 100);

            window.on("scroll", scrollYHandler);

            return search;
        };

        var getWarningApprover = function (warningApprovers, role) {
            var warningApprover = _.find(warningApprovers, function (warningApprover) {
                return warningApprover.role.name == role;
            });

            if (warningApprover) {
                return warningApprover;
            }

            return false;
        };

        var getWarningStatusHTML = function (warning) {
            var html = warning.status;

            if (warning.status.indexOf("Sent") !== -1) {
                if (warning.reminders > 1) {
                    html = html + " " + warning.reminders;
                }
                html = '<span><i class="fa fa-envelope"></i> ' + html + "</span>";
            } else if (warning.is_on_load) {
                html += "*";
            }

            return html;
        };

        var getWarningApproverStatusHTML = function (warningApprover) {
            var html = warningApprover.status;

            if (warningApprover.status.indexOf("Sent") !== -1) {
                if (warningApprover.reminders > 1) {
                    html = html + " " + warningApprover.reminders;
                }
                html = '<span><i class="fa fa-envelope"></i> ' + html + "</span>";
            }

            return html;
        };

        function isRevisedAfterResponse(status, statusUpdatedAt, contractCreatedAt) {
            var systemStatuses = ["Pending", "Sent", "Sent Reminder", "Queued"];

            if (_.includes(systemStatuses, status)) {
                return false;
            }

            if (contractCreatedAt > statusUpdatedAt) {
                return true;
            }

            return false;
        }

        var hasResponded = function (status) {
            return _.includes(WarningsModel.getResponseStatuses(), status);
        };

        var showEditWarning = function (warning, role) {
            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/warnings/warning-edit.tmpl.html",
                controller: [
                    "warning",
                    "role",
                    "$uibModalInstance",
                    "UsersModel",
                    "Loader",
                    function (warning, role, $uibModalInstance, UsersModel, Loader) {
                        var modalCtrl = this;

                        modalCtrl.role = role;

                        if (role.name == "director") {
                            modalCtrl.title = "Request Action<br>";
                            modalCtrl.statusLabel = "Request";
                            modalCtrl.statusOptions = [
                                {
                                    label: "Min Enrl Reached",
                                    value: "Min Enrl Reached",
                                    message:
                                        "This course has met DLL minimum enrollments (12 undergraduate/6 graduate) and will run with full salary.",
                                },
                                {
                                    label: "Cancel",
                                    value: "Cancel",
                                    message:
                                        "DLL Operations Team will pull the class roster, communicate with any enrolled students, and initiate cancellation of the course in MaineStreet.",
                                },
                                {
                                    label: "Pro-rate Salary",
                                    value: "Pro-rate Salary",
                                    message:
                                        "Based on the rationale provided by the Dean and/or Chair/Director, a pro-rated salary has been approved for this course. " +
                                        "Pro-rated salary will be calculated based on the higher of: enrollment on the day of cancellation warning, the first day of class, or the last day to drop class. " +
                                        "When possible, DLL will follow the payment schedule on the original teaching agreement.",
                                },
                                {
                                    label: "On-Load",
                                    value: "On-Load",
                                    message:
                                        "The course is taught on load by the faculty member with no compensation paid by DLL and the Dean and/or Chair/Director " +
                                        "requests the course to remain on the schedule as planned with enrollment below the typical DLL minimum.",
                                },
                                {
                                    label: "Other",
                                    value: "Other",
                                    message: "",
                                },
                            ];
                            modalCtrl.status = _.find(modalCtrl.statusOptions, { value: warning.approver.status });
                            modalCtrl.message = warning.approver.message;
                            modalCtrl.messagePlaceholder =
                                "Example: Department will cover all/part of salary, course is part of regular course load, etc.";
                        } else if (_.includes(_.map(roles, "name"), role.name)) {
                            modalCtrl.title = "Request Action<br>";
                            modalCtrl.statusLabel = "Request Action";
                            modalCtrl.statusOptions = [
                                {
                                    label: "Cancel",
                                    value: "Cancel",
                                },
                                {
                                    label: "Pro-rate Salary",
                                    value: "Pro-rate Salary",
                                },
                                {
                                    label: "Other",
                                    value: "Other",
                                },
                            ];
                            // modalCtrl.title += '<small><strong>Approver Role: </strong>' + role.display_name + '</small><br>';
                            modalCtrl.status = _.find(modalCtrl.statusOptions, { value: warning.approver.status });
                            modalCtrl.message = warning.approver.message;
                            modalCtrl.messagePlaceholder =
                                "Example: Department will cover all/part of salary, course is part of regular course load, etc.";
                        } else if (role.name == "instructor") {
                            modalCtrl.title = "Accept Pro-rate?<br>";
                            if (_.includes(warning.contract.instructor_unit.toUpperCase(), "AFUM")) {
                                modalCtrl.statusLabel =
                                    "Consideration is being given to running your class with less than full enrollment. " +
                                    "Are you willing to accept a pro-rated salary based on the number of students in your class? " +
                                    "The prorated salary will be at or above the minimum required by the AFUM/UMS contract for AFUM Bargaining Unit members.";
                            } else {
                                modalCtrl.statusLabel =
                                    "Consideration is being given to running your class with less than full enrollment. " +
                                    "Are you willing to accept a pro-rated salary based on number of students in your class if it is approved to be offered by your Chair/Director, Dean, and DLL?";
                            }

                            modalCtrl.statusOptions = [
                                {
                                    label: "Yes",
                                    value: "Yes",
                                },
                                {
                                    label: "No",
                                    value: "No",
                                },
                            ];
                            modalCtrl.status = _.find(modalCtrl.statusOptions, { value: warning.status });
                            modalCtrl.isOnLoad = warning.is_on_load;
                            modalCtrl.message = warning.message;
                            modalCtrl.messagePlaceholder =
                                "If there is anything you wish your Chair/Director, Dean or DLL to know during their decision process please add here.";
                        }

                        modalCtrl.title +=
                            "<small><strong>Contract: </strong>" +
                            warning.contract.user_first_name +
                            " " +
                            warning.contract.user_last_name +
                            " &mdash; " +
                            warning.contract.subject +
                            " " +
                            warning.contract.course_catalog +
                            " (" +
                            warning.contract.section_no +
                            ")</small>";
                        modalCtrl.hasResponded = hasResponded;

                        modalCtrl.update = function (status) {
                            modalCtrl.warningForm.errors = null;

                            if (role != "director") {
                                return;
                            }

                            if (status.message !== "undefined") {
                                modalCtrl.message = status.message;
                            }
                        };

                        modalCtrl.save = function () {
                            var editedWarning = {
                                status: modalCtrl.status.value,
                                message: modalCtrl.message,
                                is_on_load: modalCtrl.isOnLoad,
                            };

                            if (_.includes(_.map(roles, "name"), role.name)) {
                                Loader.showLoading("Updating Warning");
                                UsersModel.updateApproverSemesterWarning(
                                    warning.contract.semester_id,
                                    warning.id,
                                    editedWarning
                                )
                                    .then(
                                        function (updatedWarning) {
                                            $uibModalInstance.close(updatedWarning);
                                        },
                                        function (result) {
                                            if (result.errors) {
                                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                                            }
                                        }
                                    )
                                    .finally(function () {
                                        Loader.hideLoading();
                                    });
                            } else if (role.name == "instructor") {
                                Loader.showLoading("Updating Warning");
                                UsersModel.updateInstructorSemesterWarning(
                                    warning.contract.semester_id,
                                    warning.id,
                                    editedWarning
                                )
                                    .then(
                                        function (updatedWarning) {
                                            $uibModalInstance.close(updatedWarning);
                                        },
                                        function (result) {
                                            if (result.errors) {
                                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                                            }
                                        }
                                    )
                                    .finally(function () {
                                        Loader.hideLoading();
                                    });
                            }
                        };

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    warning: function () {
                        return warning;
                    },
                    role: function () {
                        return role;
                    },
                },
            });

            modalInstance.result.then(
                function (updatedWarning) {
                    _.merge(warning, updatedWarning);
                    addCustomAttributes(warning);
                    SemestersModel.clearCache();
                },
                function (result) {
                    if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                }
            );
        };

        function getPanelClass(status) {
            if (_.includes(["Queued", "Pending", "Sent", "Sent Reminder"], status)) {
                return "panel-default";
            }

            if (
                _.includes(
                    [
                        "Information Adjustment",
                        "Salary Correction",
                        "Salary Adjustment",
                        "DE Adjustment",
                        "Prorated Salary",
                        "Cancelled Course",
                        "Cancelled Course (No Fee)",
                        "Change in Instructor",
                        "Contract",
                    ],
                    status
                )
            ) {
                return "panel-info";
            }

            return "panel-primary";
        }

        function getRevisionHTML(revision, prevRevision) {
            var labels = ContractsModel.labels();
            var dates = ContractsModel.dates();

            var fields = [
                "canc_warned_enrl",
                "lesson_enrl_total",
                "lesson_enrl_total_combined",
                "course",
                "lesson_no",
                "lesson_comb_sects_id",
                "instructor",
                "salary",
                "lesson_start_date_one",
            ];

            var html = "<ul class='list-unstyled m-0'>";

            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                var value = revision[field];
                var prevValue = prevRevision && prevRevision[field];
                var wasChanged = prevValue && value != prevValue;

                if (_.includes(dates, field)) {
                    value = $filter("date")(value, "M/d/yy");
                    prevValue = prevValue && $filter("date")(prevValue, "M/d/yy");
                }

                html += "<li><b>" + labels[field] + ":</b> ";

                if (wasChanged) {
                    html += '<span class="text-warning">';
                }

                html += value;

                if (wasChanged) {
                    html += ' <s class="text-danger">' + prevValue + "</s></span>";
                }

                html += "</li>";
            }

            html += "</ul>";

            return html;
        }

        var showWarning = function (warning) {
            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/warnings/warning.tmpl.html",
                controller: [
                    "warning",
                    "$uibModalInstance",
                    function (warning, $uibModalInstance) {
                        var modalCtrl = this;

                        modalCtrl.warning = warning;

                        modalCtrl.getPanelClass = getPanelClass;
                        modalCtrl.getRevisionHTML = getRevisionHTML;

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    warning: function () {
                        return warning;
                    },
                },
            });

            modalInstance.result.catch(function (result) {
                if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
            });
        };

        var showWarningStatuses = function (warning) {
            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/warnings/warning-statuses.tmpl.html",
                controller: [
                    "warning",
                    "$uibModalInstance",
                    function (warning, $uibModalInstance) {
                        var modalCtrl = this;

                        modalCtrl.isLoading = true;

                        WarningsModel.getWarningStatuses(warning.id)
                            .then(function (statuses) {
                                modalCtrl.statuses = statuses;
                            })
                            .finally(function () {
                                modalCtrl.isLoading = false;
                            });

                        modalCtrl.warning = warning;
                        modalCtrl.getPanelClass = getPanelClass;

                        modalCtrl.getRevisionHTML = function (revision, index) {
                            var revisions = _.map(modalCtrl.statuses, "revision");

                            var prevRevision = revisions[index + 1];

                            return getRevisionHTML(revision, prevRevision);
                        };

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    warning: function () {
                        return warning;
                    },
                },
            });

            modalInstance.result.catch(function (result) {
                if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
            });
        };

        var showWarningApprover = function (warning, warningApprover) {
            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/warnings/warning-approver.tmpl.html",
                controller: [
                    "warning",
                    "$uibModalInstance",
                    function (warning, $uibModalInstance) {
                        var modalCtrl = this;

                        modalCtrl.getPanelClass = getPanelClass;
                        modalCtrl.getRevisionHTML = getRevisionHTML;

                        modalCtrl.warning = warning;
                        modalCtrl.warningApprover = warningApprover;

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    warning: function () {
                        return warning;
                    },
                },
            });

            modalInstance.result.catch(function (result) {
                if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
            });
        };

        var showWarningApproverStatuses = function (warning, warningApprover) {
            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/warnings/warning-approver-statuses.tmpl.html",
                controller: [
                    "warning",
                    "warningApprover",
                    "$uibModalInstance",
                    function (warning, warningApprover, $uibModalInstance) {
                        var modalCtrl = this;

                        modalCtrl.isLoading = true;

                        WarningsModel.getWarningApproverStatuses(warningApprover.id)
                            .then(function (statuses) {
                                modalCtrl.statuses = statuses;
                            })
                            .finally(function () {
                                modalCtrl.isLoading = false;
                            });

                        modalCtrl.warning = warning;
                        modalCtrl.warningApprover = warningApprover;
                        modalCtrl.getPanelClass = getPanelClass;

                        modalCtrl.getRevisionHTML = function (revision, index) {
                            var revisions = _.map(modalCtrl.statuses, "revision");

                            var prevRevision = revisions[index + 1];

                            return getRevisionHTML(revision, prevRevision);
                        };

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    warning: function () {
                        return warning;
                    },
                    warningApprover: function () {
                        return warningApprover;
                    },
                },
            });

            modalInstance.result.catch(function (result) {
                if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
            });
        };

        function exportWarnings(warnings, tab) {
            Loader.showLoading("Exporting Warnings");

            if (!warnings.length) {
                Loader.hideLoading();
                AlertFactory.show("No Warnings Found", "danger");
                return;
            }

            var date = $filter("date")(new Date(), "M-d-yyyy");

            var fileName = "Warnings " + _.capitalize(tab) + "_" + date + ".xlsx";

            alasql.fn.get = function (value, field) {
                if (!value && value !== 0) {
                    value = "";
                } else if (_.includes(dates, field)) {
                    value = $filter("date")(new Date(value), "M/d/yyyy");
                } else if (_.includes(times, field)) {
                    value = $filter("date")(new Date(value), "h:mm a");
                } else if (_.includes(decimals, field)) {
                    value = $filter("toFixed")(value, 2);
                }
                return value;
            };

            alasql.fn.getWarningApprover = function (warningApprovers, role, property) {
                var warningApprover = getWarningApprover(warningApprovers, role);

                if (!warningApprover) {
                    return "";
                }

                var value = warningApprover[property];

                if (!value && value !== 0) {
                    return "";
                }
                return value;
            };

            var contractFields = [
                {
                    value: "lesson_enrl_cap",
                    label: "Enrl Ca[",
                },
                {
                    value: "canc_warned_enrl",
                    label: "Canc Warned Enrl",
                },
                {
                    value: "lesson_enrl_total_combined",
                    label: "Tot Enrl Combined",
                },
                {
                    value: "course",
                    label: "Course",
                },
                {
                    value: "lesson_no",
                    label: "Class Nbr",
                },
                {
                    value: "lesson_comb_sects_id",
                    label: "Comb Sects ID",
                },
                {
                    value: "user_last_name",
                    label: "Last Name",
                },
                {
                    value: "user_first_name",
                    label: "First Name",
                },
                {
                    value: "salary",
                    label: "Salary",
                },
                {
                    value: "lesson_start_date_one",
                    label: "Start Date",
                },
                {
                    value: "status",
                    label: "Salary",
                },
            ];

            var sql = "";

            // var sql =
            //     "SELECT " +
            //     "get(contract->lesson_enrl_cap, 'lesson_enrl_cap') AS [Enrl Cap], " +
            //     "get(contract->canc_warned_enrl, 'canc_warned_enrl') AS [Canc Warned Enrl], " +
            //     "get(contract->lesson_enrl_total_combined) AS [Tot Enrl Combined], " +
            //     "get(contract->course) AS Course, " +
            //     "get(contract->lesson_no) as [Class Nbr], " +
            //     "get(contract->lesson_comb_sects_id) as [Comb Sects ID], " +
            //     "get(contract->user_last_name) AS [Last Name], " +
            //     "get(contract->user_first_name) AS [First Name], " +
            //     "decimal(contract->salary) as Salary, " +
            //     "datetime(contract->lesson_start_date_one) AS [Start Date], " +
            //     "get(status) AS [Instructor Accepts Pro-rate?], " +
            //     "get(message) AS [Instructor Message], ";

            _.forEach(roles, function (role) {
                sql +=
                    "getWarningApprover(approvers, '" +
                    role.name +
                    "', 'status') AS [" +
                    role.display_name +
                    " Requested Action], " +
                    "getWarningApprover(approvers, '" +
                    role.name +
                    "', 'message') AS [" +
                    role.display_name +
                    " Message], ";
            });

            sql += "datetime(updated_at) AS [Updated]";

            alasql
                .promise(sql + ' INTO XLSX("' + fileName + '", {headers:true}) FROM ?', [warnings])
                .then(function () {
                    Loader.hideLoading();
                })
                .catch(function (err) {
                    AlertFactory.show("Export Error", "danger");
                    console.log(err);
                    Loader.hideLoading();
                });
        }

        warningsFactory.getSearch = getSearch;

        warningsFactory.getWarningApprover = getWarningApprover;
        warningsFactory.getWarningStatusHTML = getWarningStatusHTML;
        warningsFactory.getWarningApproverStatusHTML = getWarningApproverStatusHTML;

        warningsFactory.isRevisedAfterResponse = isRevisedAfterResponse;
        warningsFactory.hasResponded = hasResponded;

        warningsFactory.showEditWarning = showEditWarning;
        warningsFactory.showWarning = showWarning;
        warningsFactory.showWarningApprover = showWarningApprover;

        warningsFactory.showWarningStatuses = showWarningStatuses;
        warningsFactory.showWarningApproverStatuses = showWarningApproverStatuses;
        warningsFactory.getRevisionHTML = getRevisionHTML;

        warningsFactory.exportWarnings = exportWarnings;

        return warningsFactory;
    },
]);
