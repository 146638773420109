angular.module("app.factories.contracts", []).factory("ContractsFactory", [
    "$window",
    "$timeout",
    "$state",
    "$q",
    "SemestersModel",
    "ContractsModel",
    "Loader",
    "$filter",
    "AlertFactory",
    "$uibModal",
    "UsersFactory",
    function (
        $window,
        $timeout,
        $state,
        $q,
        SemestersModel,
        ContractsModel,
        Loader,
        $filter,
        AlertFactory,
        $uibModal,
        UsersFactory
    ) {
        var contractsFactory = this;
        var editableContract;

        var fillable = [];
        var labels = [];
        var groups = ContractsModel.groups();
        var dates = ContractsModel.dates();
        var times = ContractsModel.times();
        var numbers = ContractsModel.numbers();
        var decimals = ContractsModel.decimals();
        var booleans = ContractsModel.booleans();
        var statuses = ContractsModel.statuses();

        function getSearchProperties(semester) {
            fillable = ContractsModel.fillable(semester);
            labels = ContractsModel.labels(semester);

            var fields = [];

            fields.push(
                {
                    label: "All",
                    value: null,
                    is_hidden: 0,
                },
                {
                    label: "Contract ID",
                    value: "id",
                    is_hidden: 0,
                }
            );

            _.forEach(fillable, function (field) {
                fields.push({
                    value: field,
                    label: labels[field],
                    group: groups[field],
                    is_hidden: 0,
                });

                if (_.includes(dates, field) || _.includes(numbers, field)) {
                    fields.push({
                        value: field,
                        label: labels[field] + " (Range)",
                        group: groups[field],
                        is_range: 1,
                        is_hidden: 0,
                    });
                }
            });

            return fields;
        }

        function getSortProperties(semester) {
            fillable = ContractsModel.fillable(semester);
            labels = ContractsModel.labels(semester);

            var fields = [];

            _.forEach(fillable, function (field) {
                fields.push({
                    value: field,
                    label: labels[field],
                    group: groups[field],
                });
            });

            return fields;
        }

        function label(prop) {
            if (labels[prop]) {
                return labels[prop];
            }

            return prop;
        }

        function format(prop, value) {
            if (prop == "type") {
                return labels[value];
            }

            if (_.includes(times, prop)) {
                return $filter("date")(value, "h:mm a");
            }

            if (_.includes(dates, prop)) {
                return $filter("date")(value, "M/d/yy");
            }

            if (_.includes(decimals, prop)) {
                return $filter("toFixed")(value, 2);
            }

            if (_.includes(booleans, prop)) {
                return value === null ? "Pending" : value === true ? "Yes" : value === false ? "No" : "";
            }

            if (_.includes(statuses, prop)) {
                return getStatusHTML(value);
            }

            return value;
        }

        function getSearch(semester) {
            var searchProps = getSearchProperties(semester);
            var sortProps = getSortProperties(semester);
            var currentSortType = "updated_at";
            var search = {
                searchProps: searchProps,
                sortProps: sortProps,
                searchCol: searchProps[0],
                sortCol: sortProps[1],
                sortType: "updated_at",
                sortReverse: true,
                limitTo: 25,
                totalItems: 0,
                orderBy: "updated_at",
                searchGT: null,
                searchLT: null,
                value: "",
                currentPage: 1,
                dates: dates,
                times: times,
                booleans: booleans,
                numbers: numbers,
                statuses: statuses,
                partialMatches: ["section_no"],
                filters: [],
                searchCols: [],
                get: function () {
                    var filter = {};
                    var searchGT = this.searchGT;
                    var searchLT = this.searchLT;
                    var col = this.searchCol.value;
                    var value = null;

                    if (col && this.searchCol.is_range && _.includes(this.dates, col)) {
                        value = this.value;
                        return function (contract) {
                            if (value == "!!") {
                                if (searchGT && searchLT) {
                                    return (
                                        new Date(contract[col]).setHours(0, 0, 0, 0) <
                                            new Date(searchGT).setHours(0, 0, 0, 0) ||
                                        new Date(contract[col]).setHours(0, 0, 0, 0) >
                                            new Date(searchLT).setHours(0, 0, 0, 0)
                                    );
                                } else if (searchGT) {
                                    return (
                                        new Date(contract[col]).setHours(0, 0, 0, 0) <
                                        new Date(searchGT).setHours(0, 0, 0, 0)
                                    );
                                } else if (searchLT) {
                                    return (
                                        new Date(contract[col]).setHours(0, 0, 0, 0) >
                                        new Date(searchLT).setHours(0, 0, 0, 0)
                                    );
                                } else {
                                    return contract[col] != null;
                                }
                            }

                            if (searchGT && searchLT) {
                                return (
                                    new Date(contract[col]).setHours(0, 0, 0, 0) >=
                                        new Date(searchGT).setHours(0, 0, 0, 0) &&
                                    new Date(contract[col]).setHours(0, 0, 0, 0) <=
                                        new Date(searchLT).setHours(0, 0, 0, 0)
                                );
                            } else if (searchGT) {
                                return (
                                    new Date(contract[col]).setHours(0, 0, 0, 0) >=
                                    new Date(searchGT).setHours(0, 0, 0, 0)
                                );
                            } else if (searchLT) {
                                return (
                                    new Date(contract[col]).setHours(0, 0, 0, 0) <=
                                    new Date(searchLT).setHours(0, 0, 0, 0)
                                );
                            } else {
                                return contract[col] == null;
                            }
                        };
                    } else if (col && this.searchCol.is_range) {
                        value = this.value;
                        return function (contract) {
                            if (value == "!!") {
                                if (searchGT && searchLT) {
                                    return (
                                        parseInt(contract[col]) < parseInt(searchGT) ||
                                        parseInt(contract[col]) > parseInt(searchLT)
                                    );
                                } else if (searchGT) {
                                    return parseInt(contract[col]) < parseInt(searchGT);
                                } else if (searchLT) {
                                    return parseInt(contract[col]) > parseInt(searchLT);
                                } else {
                                    return contract[col] != null;
                                }
                            }

                            if (searchGT && searchLT) {
                                return (
                                    parseInt(contract[col]) >= parseInt(searchGT) &&
                                    parseInt(contract[col]) <= parseInt(searchLT)
                                );
                            } else if (searchGT) {
                                return parseInt(contract[col]) >= parseInt(searchGT);
                            } else if (searchLT) {
                                return parseInt(contract[col]) <= parseInt(searchLT);
                            } else {
                                return contract[col] != null;
                            }
                        };
                    } else if (col) {
                        value = this.value;

                        if (_.includes(this.dates, col) || _.includes(this.times, col)) {
                            value = angular.fromJson(angular.toJson(this.value));
                        }

                        if (!value && value !== 0 && value !== false) {
                            value = null;
                        }

                        filter = _.set(filter, col, value);
                    } else {
                        filter = this.value;
                    }

                    return filter;
                },
                dateFilter: function () {
                    var filter = {};

                    if (!_.includes(this.dates, this.searchCol.value)) {
                        return filter;
                    }

                    if (this.searchCol.value) {
                        var value = this.value;
                        if (!value) {
                            value = null;
                        }
                        filter[this.searchCol.value] = value;
                    }

                    return filter;
                },
                stringComparator: function (a, b) {
                    return typeof a === "string" && typeof b === "string"
                        ? a.trim().toUpperCase() === b.trim().toUpperCase()
                        : angular.equals(a, b);
                },
                numberComparator: function (a, b) {
                    return parseFloat(a) == parseFloat(b);
                },
                dateComparator: function (a, b) {
                    return angular.equals(new Date(a).setHours(0, 0, 0, 0), new Date(b).setHours(0, 0, 0, 0));
                },
                timeComparator: function (a, b) {
                    var dateA = new Date(a);
                    var dateB = new Date(b);

                    if (!b) {
                        return a == b;
                    }

                    return (
                        angular.equals(dateA.getHours(), dateB.getHours()) &&
                        angular.equals(dateA.getMinutes(), dateB.getMinutes())
                    );
                },
                updateSortCol: function () {
                    var searchCol = this.searchCol;

                    if (this.searchCol.value) {
                        var index = _.findIndex(this.sortProps, function (property) {
                            return property.value == searchCol.value;
                        });
                        if (index >= 0) {
                            this.sortCol = this.sortProps[index];
                        }
                    }
                    this.value = null;
                },
                pageYOffset: null,
                minLimitTo: null,
                resetLimitTo: function () {
                    if (this.limitTo > this.minLimitTo) {
                        $timeout(function () {
                            search.limitTo = search.minLimitTo || search.limitTo;
                        });
                    }
                },
                setLimitTo: function (totalItems) {
                    if (this.limitTo < totalItems) {
                        if (!this.pageYOffset) {
                            this.pageYOffset = $window.pageYOffset;
                        }

                        if (this.pageYOffset && !this.minLimitTo) {
                            this.minLimitTo = this.limitTo;
                        }

                        this.limitTo += 25;
                    }
                },
                statusOrder: function (item) {
                    var value = _.get(item, currentSortType);
                    if (value === null) return 1;
                    if (value === false) return 2;
                    if (value === true) return 3;
                    return 4;
                },
                numberOrder: function (item) {
                    var value = _.get(item, currentSortType);

                    if (isNaN(+value)) {
                        return value;
                    }

                    return +value;
                },
                sort: function (sortType) {
                    this.limitTo = this.minLimitTo || this.limitTo;
                    this.sortReverse = !this.sortReverse;
                    this.sortType = sortType;

                    currentSortType = sortType;

                    if (_.includes(this.numbers, sortType)) {
                        this.orderBy = [this.numberOrder, sortType];
                    } else if (_.includes(this.statuses, sortType)) {
                        this.orderBy = [this.statusOrder, sortType];
                    } else if (sortType == "semester") {
                        this.orderBy = ["semester.year", "semester.term.weight"];
                    } else {
                        this.orderBy = sortType;
                    }

                    this.currentPage = 1;
                },
                format: format,
                hasSearchCol: function (searchCol) {
                    return this.searchCol.value == searchCol || _.includes(_.map(this.searchCols, "value"), searchCol);
                },
                filterContract: function (contract) {
                    var filter = {};
                    filter.expression = { id: "!" + contract.id };
                    var searchCol = {};
                    searchCol.label = "Contract ID";
                    searchCol.value = "id";
                    searchCol.is_hidden = 0;
                    filter.searchCol = searchCol;
                    filter.searchVal = this.format("id", contract.id);
                    filter.comparator = this.numberComparator;
                    filter.html = "Contract ID: " + contract.id;
                    filter.value = contract.id;
                    filter.type = "excluded";

                    this.filters.push(filter);
                },
                isSearchColHidden: function () {
                    if (!this.searchCol.value) {
                        return true;
                    }

                    if (_.includes(this.defaultCols, this.searchCol.value)) {
                        return true;
                    }

                    return false;
                },
                isSortColHidden: function () {
                    if (!this.sortCol.value) {
                        return true;
                    }

                    if (this.sortCol.value == this.searchCol.value) {
                        return true;
                    }

                    if (_.includes(this.defaultCols, this.sortCol.value)) {
                        return true;
                    }

                    if (_.includes(_.values(_.map(this.searchCols, "value")), this.sortCol.value)) {
                        return true;
                    }

                    return false;
                },
                isReplaceColHidden: function (replaceCol) {
                    if (!replaceCol) {
                        return true;
                    }

                    if (replaceCol == this.searchCol.value) {
                        return true;
                    }

                    if (replaceCol == this.sortCol.value) {
                        return true;
                    }

                    if (_.includes(this.defaultCols, replaceCol)) {
                        return true;
                    }

                    if (_.includes(_.values(_.map(this.searchCols, "value")), replaceCol)) {
                        return true;
                    }

                    return false;
                },
                defaultCols: [
                    "department",
                    "subject",
                    "course_catalog",
                    "section_no",
                    "is_completed",
                    "is_approved",
                    "is_accepted",
                    "in_proposal",
                    "updated_at",
                ],
                defaultColsFilter: function (defaultCols) {
                    return function (col) {
                        return !_.includes(defaultCols, col.value);
                    };
                },
                clear: function () {
                    this.searchCol = searchProps[0];
                    this.searchGT = null;
                    this.searchLT = null;
                    this.value = "";
                    this.filters = [];
                    this.searchCols = [];
                },
                cleanUp: function () {
                    window.off("scroll", scrollYHandler);
                },
            };

            var window = angular.element($window);

            var scrollYHandler = _.throttle(function (e) {
                if (search.pageYOffset && $window.pageYOffset + search.pageYOffset * 0.25 < search.pageYOffset) {
                    search.resetLimitTo();
                }
            }, 100);

            window.on("scroll", scrollYHandler);

            return search;
        }

        function getEditable(contract, isCreating) {
            var editable = {};
            var canEdit = true;

            if (contract.is_accepted) {
                canEdit = false;
            }

            if (contract.is_declined === false) {
                canEdit = false;
            }

            var default_fields = [
                "department",
                "subject",

                "course_catalog",
                "course_title",

                "section_no",
                "section_min_units",
                "section_max_units",

                "lesson_no",

                "lesson_facil_id_one",
                "lesson_days_one",
                "lesson_start_date_one",
                "lesson_end_date_one",
                "lesson_start_time_one",
                "lesson_end_time_one",

                "lesson_facil_id_two",
                "lesson_days_two",
                "lesson_start_date_two",
                "lesson_end_date_two",
                "lesson_start_time_two",
                "lesson_end_time_two",

                "lesson_facil_id_three",
                "lesson_days_three",
                "lesson_start_date_three",
                "lesson_end_date_three",
                "lesson_start_time_three",
                "lesson_end_time_three",

                "lesson_facil_id_four",
                "lesson_days_four",
                "lesson_start_date_four",
                "lesson_end_date_four",
                "lesson_start_time_four",
                "lesson_end_time_four",

                "lesson_facil_id_five",
                "lesson_days_five",
                "lesson_start_date_five",
                "lesson_end_date_five",
                "lesson_start_time_five",
                "lesson_end_time_five",

                "user_first_name",
                "user_last_name",
                "user_email",
                "instructor_address",
                "instructor_city",
                "instructor_state",
                "instructor_zip",
                "instructor_college",
                "instructor_department",
                "instructor_rank",
                "instructor_unit",
                "instructor_position_no",
                "instructor_credit_hr_rate",
                "instructor_patfa_service_credits",
                "instructor_job_code",
                "instructor_is_de_adj_eligible",
                "instructor_is_de_adj_grandfathered",
                "instructor_has_fhc_fee",

                "new_rate_add",
                "additional_lab_hrs",
                "fhc_add_comp",
            ];

            var class_fields = ["lesson_comb_sects_id"];

            var salary_fields = ["salary", "payments", "pay_start_date", "pay_end_date", "payroll_date"];

            var salary_adj_fields = [
                "adj_salary",
                "adj_payments",
                "adj_pay_start_date",
                "adj_pay_end_date",
                "adj_payroll_date",
                "amount_prev_paid",
            ];

            var canc_fields = ["is_canc", "canc_enrl", "canc_pay_percentage", "canc_date"];

            var prorated_salary_fields = ["has_prorated_salary", "prorated_salary_enrl", "prorated_salary_date"];

            var de_adj_fields = ["has_de_adj", "de_adj_enrl", "de_adj_overload", "de_adj_date"];

            var exempt_fields = [
                "college",

                "is_dll_support_approved",

                "section_mode",
                "section_offer_no",
                "section_component",

                "lesson_status",
                "lesson_campus",
                "lesson_session",
                "lesson_weeks",

                "is_lesson_appl_sci",
                "is_lesson_art_cr_exp",
                "is_lesson_cap_exp",
                "is_lesson_cul_diver",
                "is_lesson_ethics",
                "is_lesson_pop_env",
                "is_lesson_quant_lit",
                "is_lesson_sci_know",
                "is_lesson_soc_cont",
                "is_lesson_west_cul",
                "is_lesson_writing_intensive",
                "is_lesson_coop_educ",
                "is_lesson_remote_taught",

                "lesson_start_date",
                "lesson_end_date",

                "lesson_last_day_add",
                "lesson_last_day_drop",
                "lesson_last_day_add_enrl",
                "lesson_last_day_drop_enrl",
                "lesson_enrl_total",
                "lesson_enrl_cap",

                "is_canc_warn_disabled",
                "has_canc_warned",
                "canc_warned_enrl",
                "canc_warned_date",

                "has_de_adj",
                "de_adj_enrl",
                "de_adj_date",

                "account_no_sem",
                "account_dept_id",
                "account_no_dept",
                "account_class",
                "account_fund",
                "account_project",
                "account_program",
                "account_amount",

                "account_no_sem_two",
                "account_dept_id_two",
                "account_no_dept_two",
                "account_class_two",
                "account_fund_two",
                "account_project_two",
                "account_program_two",
                "account_amount_two",

                "account_no_sem_three",
                "account_dept_id_three",
                "account_no_dept_three",
                "account_class_three",
                "account_fund_three",
                "account_project_three",
                "account_program_three",
                "account_amount_three",

                "is_first_time_taught_dist",
                "course_prep_fee_amount",
                "course_prep_fee_payroll_date",
                "course_prep_fee_paid_term",

                "payroll_date",
                "adj_payroll_date",

                "month_one",
                "month_two",
                "month_three",
                "month_four",
                "month_five",

                "notes_content",
            ];

            class_correction_fields = _.concat(default_fields, class_fields, salary_fields);
            salary_correction_fields = _.concat(default_fields, salary_fields);
            salary_adj_fields = _.concat(default_fields, salary_adj_fields);
            de_adj_fields = _.concat(default_fields, de_adj_fields, salary_adj_fields);
            canc_fields = _.concat(default_fields, canc_fields, salary_adj_fields);
            prorated_salary_fields = _.concat(default_fields, prorated_salary_fields, salary_adj_fields);
            instructor_change_fields = _.concat(default_fields, salary_fields);

            if (contract.is_canc) {
                instructor_change_fields = _.concat(instructor_change_fields, canc_fields);
            }

            _.forOwn(contract, function (value, key) {
                if (!editable[key]) {
                    editable[key] = {};
                    editable[key].contract = {};
                    editable[key].contract.id = contract.id;
                    editable[key].contract.parent_id = contract.parent_id;
                    editable[key].contract[key] = contract[key];
                    editable[key].key = key;
                    editable[key].value = value;
                    editable[key].visible = false;
                    editable[key].error = false;
                    editable[key].editing = false;
                    editable[key].canEdit = function () {
                        if (!contract.isCreating) {
                            return canEdit || _.includes(exempt_fields, key);
                        }

                        if (contract.type == "class_correction" && _.includes(class_correction_fields, key)) {
                            return true;
                        }

                        if (contract.type == "salary_correction" && _.includes(salary_correction_fields, key)) {
                            return true;
                        }

                        if (contract.type == "salary_adj" && _.includes(salary_adj_fields, key)) {
                            return true;
                        }

                        if (contract.type == "canc" && _.includes(canc_fields, key)) {
                            return true;
                        }

                        if (contract.type == "canc_no_fee" && _.includes(canc_fields, key)) {
                            return true;
                        }

                        if (contract.type == "prorated_salary" && _.includes(prorated_salary_fields, key)) {
                            return true;
                        }

                        if (contract.type == "de_adj" && _.includes(de_adj_fields, key)) {
                            return true;
                        }

                        if (contract.type == "instructor_change" && _.includes(instructor_change_fields, key)) {
                            return true;
                        }

                        if (_.includes(exempt_fields, key)) {
                            return true;
                        }

                        return canEdit;
                    };

                    editable[key].isCreating = isCreating;

                    editable[key].showEdit = function () {
                        if (
                            UsersFactory.hasPermission("edit-contracts") &&
                            !editable[key].editing &&
                            editable[key].canEdit()
                        ) {
                            editable[key].visible = true;
                        }
                    };

                    editable[key].hideEdit = function () {
                        this.visible = false;
                    };

                    editable[key].edit = function (model) {
                        this.visible = false;
                        this.editing = true;

                        if (model) {
                            model.$setViewValue(model.$viewValue ? model.$viewValue : "");
                            model.$render();
                        }
                    };

                    editable[key].cancelEdit = function () {
                        this.value = editableContract[this.key];
                        this.editing = false;
                    };

                    editable[key].saveEdit = function () {
                        var deferred = $q.defer();

                        if (this.value === undefined) {
                            deferred.resolve();
                            return deferred.promise;
                        }

                        var editable = this;

                        var key = this.key;

                        this.contract[key] = this.value;

                        if (this.isCreating || editableContract[key] == this.value) {
                            editableContract[key] = this.value;
                            this.editing = false;
                            this.error = false;
                            deferred.resolve(false);
                            return deferred.promise;
                        }

                        function onSuccess(updatedContract) {
                            _.assign(editableContract, updatedContract);
                            editable.editing = false;
                            editable.error = false;
                            deferred.resolve(updatedContract);
                            editable.saving = false;
                        }

                        function onError(result) {
                            editable.contract[key] = editableContract[key];
                            editable.error = true;
                            deferred.reject(result);
                            editable.saving = false;
                        }

                        this.saving = true;

                        ContractsModel.updateContract(this.contract).then(function (updatedContract) {
                            onSuccess(updatedContract);
                        }, onError);

                        return deferred.promise;
                    };
                }
            });

            editableContract = contract;

            return editable;
        }

        function updatePredefinedField(field, semester) {
            var deferred = $q.defer();

            var modalInstance = $uibModal.open({
                size: "lg",
                templateUrl:
                    "admin/semesters/contracts/contract/predefined-fields/contract-predefined-fields.tmpl.html",
                controller: "ContractPredfinedFieldsCtrl as contractPredefinedFieldsCtrl",
                resolve: {
                    semester: function () {
                        return semester ? semester : null;
                    },
                    field: function () {
                        return field;
                    },
                },
            });

            modalInstance.result
                .then(function () {
                    deferred.resolve(true);
                })
                .catch(function (result) {
                    deferred.resolve(false);
                    if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                });

            return deferred.promise;
        }

        function getPredefinedField(field, semester) {
            var deferred = $q.defer();

            ContractsModel.getContractsMeta().then(function (contractsMeta) {
                var predefinedField = [];

                predefinedField = _.find(contractsMeta, function (meta) {
                    if (semester && meta.value.semester) {
                        return meta.key == field && meta.value.semester == semester.term.name;
                    } else {
                        return meta.key == field;
                    }
                });

                if (!predefinedField) {
                    deferred.resolve([]);
                } else {
                    deferred.resolve(predefinedField.value.items);
                }
            });

            return deferred.promise;
        }

        function getCompletionStatusChecks() {
            return [
                "department",
                "subject",
                "course_catalog",
                "course_title",
                "section_no",
                "section_max_units",
                "lesson_start_date_one",
                "lesson_end_date_one",
                "user_ums_id",
                "user_email",
                "user_first_name",
                "user_last_name",
                "instructor_unit",
                "instructor_rank",
                "salary",
                "payments",
            ];
        }

        function getProratedSalaryEnrl(contract) {
            contract.editable.prorated_salary_enrl.value = _.max([
                contract.canc_warned_enrl,
                contract.lesson_last_day_add_enrl,
                contract.lesson_last_day_drop_enrl,
            ]);
        }

        function calculateProratedSalary(contract, type) {
            if (!contract) {
                return 0;
            }

            var salary = 0;
            var prorated_salary_enrl = 0;
            var full_enrl = 6;

            if (contract.salary) {
                salary = contract.salary;
            }
            if (contract.prorated_salary_enrl) {
                prorated_salary_enrl = contract.prorated_salary_enrl;
            }

            if (prorated_salary_enrl == 0) {
                return 0;
            }

            if (type == "UnderG") {
                full_enrl = 12;
            }

            return (salary / full_enrl) * prorated_salary_enrl;
        }

        function calculateCancSalary(contract) {
            if (!contract) {
                return 0;
            }

            var salary = 0;
            var canc_pay_percentage = 0;

            if (contract.salary) {
                salary = contract.salary;
            }

            if (contract.canc_pay_percentage) {
                canc_pay_percentage = contract.canc_pay_percentage;
            }

            return salary * canc_pay_percentage;
        }

        function calculateSalary(contract) {
            if (!contract) {
                return 0;
            }

            var instructor_credit_hr_rate = 0;
            var section_min_units = 0;
            var additional_lab_hrs = 0;
            var fhc_add_comp = 0;

            if (contract.instructor_credit_hr_rate) {
                instructor_credit_hr_rate = contract.instructor_credit_hr_rate;
            }

            if (contract.section_min_units) {
                section_min_units = contract.section_min_units;
            }

            if (contract.additional_lab_hrs) {
                additional_lab_hrs = contract.additional_lab_hrs;
            }

            if (contract.fhc_add_comp) {
                fhc_add_comp = contract.fhc_add_comp;
            }

            return instructor_credit_hr_rate * section_min_units + additional_lab_hrs + fhc_add_comp;
        }

        function calculateMonthly(contract) {
            var monthly = 0;

            if (contract && contract.salary && contract.payments) {
                monthly = contract.salary / contract.payments;
            }

            return monthly;
        }

        function calculateHourlyRate(contract) {
            var hourly_rate = 0;
            var monthly = calculateMonthly(contract);

            if (contract && monthly && contract.section_min_units) {
                hourly_rate = monthly / contract.section_min_units;
            }

            return hourly_rate;
        }

        function calculateAdjSalary(contract) {
            if (!contract) {
                return 0;
            }

            var instructor_credit_hr_rate = 0;
            var section_min_units = 0;
            var de_adj_overload = 0;
            var new_rate_add = 0;
            var additional_lab_hrs = 0;
            var fhc_add_comp = 0;

            if (contract.instructor_credit_hr_rate) {
                instructor_credit_hr_rate = contract.instructor_credit_hr_rate;
            }

            if (contract.section_min_units) {
                section_min_units = contract.section_min_units;
            }

            if (contract.de_adj_overload) {
                de_adj_overload = contract.de_adj_overload;
            }

            if (contract.new_rate_add) {
                new_rate_add = contract.new_rate_add;
            }

            if (contract.additional_lab_hrs) {
                additional_lab_hrs = contract.additional_lab_hrs;
            }

            if (contract.fhc_add_comp) {
                fhc_add_comp = contract.fhc_add_comp;
            }

            return (
                instructor_credit_hr_rate * section_min_units +
                de_adj_overload +
                new_rate_add +
                additional_lab_hrs +
                fhc_add_comp
            );
        }

        function calculateAdjMonthly(contract) {
            var adj_monthly = 0;

            if (contract && contract.adj_salary && contract.adj_payments) {
                adj_monthly = (contract.adj_salary - contract.amount_prev_paid) / contract.adj_payments;
            }

            return adj_monthly;
        }

        function calculateAdjHourlyRate(contract) {
            var adj_hourly_rate = 0;
            var adj_monthly = calculateAdjMonthly(contract);

            if (contract && adj_monthly && contract.section_min_units) {
                adj_hourly_rate = adj_monthly / contract.section_min_units;
            }

            return adj_hourly_rate;
        }

        function exportCustomSpreadsheet(contracts, spreadsheetGroups, spreadsheet) {
            var deferred = $q.defer();

            var modalInstance = $uibModal.open({
                templateUrl: "admin/semesters/contracts/export/contracts-export.tmpl.html",
                controller: "ExportContractsCtrl as exportContractsCtrl",
                windowTopClass: "admin-semesters-semester-contracts-export",
                backdrop: "static",
                resolve: {
                    contracts: function () {
                        return contracts;
                    },
                    spreadsheetGroups: function () {
                        return spreadsheetGroups;
                    },
                    spreadsheet: function () {
                        return spreadsheet;
                    },
                },
            });

            modalInstance.result
                .then(function () {
                    deferred.resolve();
                })
                .catch(function (result) {
                    deferred.reject();
                    if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                });

            return deferred.promise;
        }

        function exportSpreadsheet(contracts, spreadsheet) {
            if (!contracts) {
                return;
            }

            Loader.showLoading("Loading Semester");
            SemestersModel.getSemester(contracts[0].semester_id).then(function (semester) {
                Loader.hideLoading();
                Loader.showLoading("Exporting Contracts");

                contracts = angular.copy(contracts);
                var errors = [];

                var date = $filter("date")(new Date(), "M-d-yy");
                var name = spreadsheet.value.name ? spreadsheet.value.name : "Custom Spreadsheet";
                var fileName = contracts[0].semester_term + "_" + name + "_" + date + ".xlsx";

                alasql.fn.get = function (value, field) {
                    if (!value && value !== 0) {
                        value = "";
                    } else if (_.includes(dates, field)) {
                        value = $filter("date")(new Date(value), "M/d/yyyy");
                    } else if (_.includes(times, field)) {
                        value = $filter("date")(new Date(value), "h:mm a");
                    } else if (_.includes(decimals, field)) {
                        value = $filter("toFixed")(value, 2);
                    }
                    return value;
                };

                var sql = [];

                _.forEach(spreadsheet.value.fields, function (field) {
                    if (field.type == "addon") {
                        if (!field.value && field.value !== 0) {
                            field.value = "";
                        }
                        sql.push('"' + field.value + '" AS [' + field.label + "]");
                    } else if (field.value == "months") {
                        var months = ["month_one", "month_two", "month_three", "month_four", "month_five"];
                        _.forEach(semester.term.months, function (month, i) {
                            sql.push("get(" + months[i] + ', "' + months[i] + '") AS [' + month.short + "]");
                        });
                    } else {
                        sql.push("get(" + field.value + ', "' + field.value + '") AS [' + field.label + "]");
                        console.log(sql);
                    }
                });

                sql = "SELECT " + sql.join(", ") + ' INTO XLSX("' + fileName + '", {headers:true}) FROM ?';

                _.forEach(contracts, function (contract) {
                    if (spreadsheet.value.isAccepted && contract.status !== "Accepted") {
                        errors.push(
                            "Contract has not been accepted by Employee ID " +
                                contract.user_ums_id +
                                " for Class Nbr " +
                                contract.lesson_no
                        );
                        return;
                    }

                    if (
                        spreadsheet.value.isRevision &&
                        (contract.type == "salary_adj" ||
                            contract.type == "prorated_salary" ||
                            contract.type == "de_adj" ||
                            contract.type == "canc" ||
                            contract.type == "canc_no_fee")
                    ) {
                        contract.salary = contract.adj_salary;
                        contract.payments = contract.adj_payments;
                        contract.pay_start_date = contract.adj_pay_start_date;
                        contract.pay_end_date = contract.adj_pay_end_date;
                        contract.payroll_date = contract.adj_payroll_date;
                        contract.monthly = contract.adj_monthly;
                        contract.hourly_rate = contract.adj_hourly_rate;
                    }

                    if (spreadsheet.value.isRevision && spreadsheet.value.isCanc) {
                        if (contract.type == "canc" || contract.type == "canc_no_fee") {
                            contract.salary = 0;
                        } else {
                            contract.adj_salary = "";
                        }
                    }
                });

                if (spreadsheet.value.isAccepted && errors.length) {
                    errors.unshift("The selected contracts have not all been accepted.");
                    AlertFactory.show(errors.join("\r\n"), "danger");
                    Loader.hideLoading();
                    return false;
                }

                alasql
                    .promise(sql, [contracts])
                    .then(function () {
                        Loader.hideLoading();
                    })
                    .catch(function (err) {
                        AlertFactory.show("Export Error", "danger");
                        console.log(err);
                        Loader.hideLoading();
                    });
            });
        }

        function deleteSpreadsheet(spreadsheet) {
            var deferred = $q.defer();

            var modalInstance = $uibModal.open({
                templateUrl: "admin/semesters/contracts/export/delete/contracts-export-delete.tmpl.html",
                controller: "DeleteExportContractsCtrl as deleteExportContractsCtrl",
                windowTopClass: "admin-semesters-semester-contracts-export-delete",
                resolve: {
                    spreadsheet: function () {
                        return spreadsheet;
                    },
                },
            });

            modalInstance.result
                .then(function () {
                    deferred.resolve();
                })
                .catch(function (result) {
                    deferred.reject();
                    if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                });

            return deferred.promise;
        }

        function isState(state) {
            if ((angular.isArray(state) && _.includes(state, $state.current.name)) || state == $state.current.name) {
                return true;
            }

            return false;
        }

        function includesState(state) {
            if ($state.includes(state)) {
                return true;
            }

            return false;
        }

        function getRowClass(contract) {
            if (contract.is_canc || contract.lesson_status == "Cancelled") {
                return "danger";
            }

            if (
                contract.has_prorated_salary &&
                (contract.is_warning_resolved || (contract.warning && contract.warning.is_resolved))
            ) {
                return "prorated";
            }

            if (contract.is_warning_resolved || (contract.warning && contract.warning.is_resolved)) {
                return "success";
            }

            if (contract.has_canc_warned) {
                return "warning";
            }
        }

        function getStatusPopoverHTML(statusAttribute) {
            var html = "";

            if (statusAttribute == "is_completed") {
                html =
                    '<div><span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>&mdash;All completed</div>';
                html +=
                    '<div><span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span>&mdash;At least one completed</div>';
                html +=
                    '<div><span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>&mdash;None completed</div>';
            }

            if (statusAttribute == "is_approved") {
                html =
                    '<div><span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>&mdash;All approved</div>';
                html +=
                    '<div><span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span>&mdash;At least one approved</div>';
                html +=
                    '<div><span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>&mdash;None approved</div>';
            }

            if (statusAttribute == "is_accepted") {
                html =
                    '<div><span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>&mdash;Accepted</div>';
                html +=
                    '<div><span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span>&mdash;Sent</div>';
                html +=
                    '<div><span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>&mdash;Not sent</div>';
            }

            return html;
        }

        function getStatusHTML(status) {
            var html = "";

            if (status === true) {
                html = '<span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>';
            } else if (status === null) {
                html = '<span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>';
            } else if (status === false) {
                html = '<span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span>';
            }

            return html;
        }

        function getSettings() {
            var defaults = ContractsModel.getSettings();
            var saved = $window.localStorage.getItem("settings");
            if (saved) {
                try {
                    return Object.assign({}, defaults, JSON.parse(saved));
                } catch (e) {
                    console.warn("Failed to parse localStorage settings:", e);
                }
            }
            return defaults;
        }

        function setSettings(settings) {
            $window.localStorage.setItem("settings", JSON.stringify(settings));
        }

        function watchSettings($scope, settingsObj, keysToWatch) {
            if (Array.isArray(keysToWatch)) {
                $scope.$watchGroup(
                    keysToWatch.map(function (k) {
                        return function () {
                            return settingsObj[k];
                        };
                    }),
                    function () {
                        setSettings(settingsObj);
                    }
                );
            } else {
                $scope.$watch(
                    function () {
                        return settingsObj;
                    },
                    function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            setSettings(newVal);
                        }
                    },
                    true // deep watch
                );
            }
        }

        contractsFactory.getSettings = getSettings;
        contractsFactory.watchSettings = watchSettings;

        contractsFactory.getEditable = getEditable;

        contractsFactory.getStatusPopoverHTML = getStatusPopoverHTML;
        contractsFactory.getStatusHTML = getStatusHTML;

        contractsFactory.isState = isState;
        contractsFactory.includesState = includesState;

        contractsFactory.getSearch = getSearch;
        contractsFactory.getSearchProperties = getSearchProperties;
        contractsFactory.getSortProperties = getSortProperties;

        contractsFactory.getCompletionStatusChecks = getCompletionStatusChecks;

        contractsFactory.getPredefinedField = getPredefinedField;
        contractsFactory.updatePredefinedField = updatePredefinedField;

        contractsFactory.label = label;
        contractsFactory.format = format;

        contractsFactory.getProratedSalaryEnrl = getProratedSalaryEnrl;
        contractsFactory.calculateProratedSalary = calculateProratedSalary;
        contractsFactory.calculateCancSalary = calculateCancSalary;
        contractsFactory.calculateSalary = calculateSalary;
        contractsFactory.calculateMonthly = calculateMonthly;
        contractsFactory.calculateHourlyRate = calculateHourlyRate;
        contractsFactory.calculateAdjSalary = calculateAdjSalary;
        contractsFactory.calculateAdjMonthly = calculateAdjMonthly;
        contractsFactory.calculateAdjHourlyRate = calculateAdjHourlyRate;

        contractsFactory.exportCustomSpreadsheet = exportCustomSpreadsheet;
        contractsFactory.exportSpreadsheet = exportSpreadsheet;
        contractsFactory.deleteSpreadsheet = deleteSpreadsheet;

        contractsFactory.getRowClass = getRowClass;

        return contractsFactory;
    },
]);
