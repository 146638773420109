angular
    .module("admin.instructors.instructor", [
        "admin.instructors.instructor.predefined-fields",
        "admin.instructors.instructor.audits",
        "admin.instructors.instructor.contracts",
        "app.factories.audits",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.instructor", {
            url: "/{instructorId:int}",
            views: {
                "": {
                    templateUrl: "admin/instructors/instructor/instructor.tmpl.html",
                    controller: "InstructorCtrl as instructorCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Instructor",
            },
            resolve: {
                instructor: [
                    "$stateParams",
                    "$q",
                    "InstructorsModel",
                    "Loader",
                    function ($stateParams, $q, InstructorsModel, Loader) {
                        var instructorId = $stateParams.instructorId;
                        var deferred = $q.defer();

                        Loader.showLoading("Loading Instructor");
                        InstructorsModel.getInstructor(instructorId)
                            .then(
                                function (instructor) {
                                    deferred.resolve(instructor);
                                },
                                function () {
                                    deferred.reject();
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });

                        return deferred.promise;
                    },
                ],
            },
        });
    })
    .controller("InstructorCtrl", [
        "currentUser",
        "instructor",
        "$scope",
        "$state",
        "$uibModal",
        "$timeout",
        "$anchorScroll",
        "SemestersModel",
        "InstructorsModel",
        "NotesModel",
        "NotesFactory",
        "InstructorsFactory",
        "UsersFactory",
        "AlertFactory",
        "AuditsFactory",
        "Loader",
        function (
            currentUser,
            instructor,
            $scope,
            $state,
            $uibModal,
            $timeout,
            $anchorScroll,
            SemestersModel,
            InstructorsModel,
            NotesModel,
            NotesFactory,
            InstructorsFactory,
            UsersFactory,
            AlertFactory,
            AuditsFactory,
            Loader
        ) {
            var instructorCtrl = this;

            instructorCtrl.currentUser = currentUser;

            instructor.editable = InstructorsFactory.getEditable(instructor);
            instructor.user.editable = UsersFactory.getEditable(instructor.user);
            instructorCtrl.instructor = instructor;

            instructorCtrl.showInstructor = true;
            instructorCtrl.showNotes = true;
            instructorCtrl.isCreatingNote = false;

            $timeout(function () {
                $anchorScroll();
            });

            SemestersModel.getSemesters().then(function (semesters) {
                if (semesters.length > 0) {
                    instructorCtrl.semester = semesters[0];
                }
            });

            instructorCtrl.deleteInstructor = function (instructor) {
                $uibModal.open({
                    animation: true,
                    templateUrl: "admin/instructors/delete/instructor-delete.tmpl.html",
                    controller: "DeleteInstructorCtrl as deleteInstructorCtrl",
                    resolve: {
                        instructor: function () {
                            return instructor;
                        },
                    },
                });
            };

            instructorCtrl.includesState = function (stateName) {
                return $state.current.name.includes(stateName);
            };

            instructorCtrl.saveEdit = function (editable) {
                instructorCtrl.lastSaved = "Saving...";

                var oldVal = instructor[editable.key];

                editable.saveEdit().then(
                    function (updatedInstructor) {
                        if (editable.isCreating) {
                            return;
                        }

                        if (updatedInstructor) {
                            _.merge(instructor, updatedInstructor);
                            $scope.$emit("instructors.update", updatedInstructor);
                            instructorCtrl.audits[editable.key] = AuditsFactory.formatAudit(
                                InstructorsFactory,
                                editable.key,
                                oldVal,
                                editable.value,
                                updatedInstructor.updated_at
                            );
                        }
                    },
                    function (result) {
                        AlertFactory.show(result.errors, "danger");

                        $timeout(function () {
                            instructorCtrl.instructorForm[editable.key].$invalid = true;
                        });
                    }
                );
            };

            /**
             * Notes
             */

            instructorCtrl.showCreateNote = function () {
                instructorCtrl.note = "";
                instructorCtrl.isCreatingNote = true;
                instructorCtrl.showNotes = true;
            };

            instructorCtrl.createNote = function (instructorId, noteableType, note) {
                Loader.showLoading("Creating Note");
                NotesModel.createNote(instructorId, noteableType, note)
                    .then(
                        function (newNote) {
                            instructor.updated_at = newNote.noteable.updated_at;
                            instructor.notes.unshift(newNote);
                            instructor.has_notes = true;
                            instructorCtrl.isCreatingNote = false;
                            instructorCtrl.showNotes = true;
                            $scope.$emit("instructors.update", instructor);
                        },
                        function (result) {
                            AlertFactory.show(result.errors, "danger");
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            instructorCtrl.deleteNote = function (instructor, note) {
                NotesFactory.deleteNote(instructor, note)
                    .then(function (deletedNote) {
                        instructor.updated_at = deletedNote.noteable.updated_at;
                        _.remove(instructor.notes, function (instructorNote) {
                            return instructorNote.id == deletedNote.id;
                        });
                        instructor.has_notes = instructor.notes.length > 0;
                        $scope.$emit("instructors.update", instructor);
                    })
                    .catch(function () {});
            };

            /**
             * Predefined Fields
             */

            function setPredefinedField(field) {
                InstructorsFactory.getPredefinedField(field).then(function (items) {
                    instructorCtrl[field + "_options"] = items;
                });
            }

            instructorCtrl.updatePredefinedField = function (field) {
                InstructorsFactory.updatePredefinedField(field).then(function (isUpdated) {
                    if (isUpdated) {
                        setPredefinedField(field);
                    }
                });
            };

            _.forEach(InstructorsModel.predefinedFields(), function (field) {
                setPredefinedField(field);
            });

            InstructorsModel.getAudits(instructor.user_id, { all: true }).then(function (result) {
                instructorCtrl.audits = AuditsFactory.getLatestFieldChanges(result.data, InstructorsFactory);
            });

            instructorCtrl.selectOptions = InstructorsFactory.getSelectOptions();
        },
    ]);
