angular.module("app.factories.audits", []).factory("AuditsFactory", [
    "$filter",
    function ($filter) {
        var auditsFactory = {};

        function formatAudit(formatter, field, oldVal, newVal, date) {
            var formatFn =
                formatter && formatter.format
                    ? formatter.format
                    : function (field, value) {
                          return value;
                      };

            var labelFn =
                formatter && formatter.label
                    ? formatter.label
                    : function (field) {
                          return field;
                      };

            oldVal = formatFn(field, oldVal);
            oldVal = oldVal !== undefined && oldVal !== null ? oldVal : "NULL";

            newVal = formatFn(field, newVal);
            newVal = newVal !== undefined && newVal !== null ? newVal : "NULL";

            return {
                old: oldVal,
                new: newVal,
                label: labelFn(field),
                date: date,
                formattedDate: $filter("date")(date, "M/d/yy"),
            };
        }

        function getLatestFieldChanges(audits, formatter) {
            var result = {};
            for (var i = 0; i < audits.length; i++) {
                var audit = audits[i];
                var date = new Date(audit.created_at);
                for (var field in audit.modified) {
                    if (!result[field] || new Date(result[field].date) < date) {
                        result[field] = formatAudit(
                            formatter,
                            field,
                            audit.modified[field].old,
                            audit.modified[field].new,
                            audit.created_at
                        );
                    }
                }
            }
            return result;
        }

        auditsFactory.getLatestFieldChanges = getLatestFieldChanges;
        auditsFactory.formatAudit = formatAudit;

        return auditsFactory;
    },
]);
