angular.module("app.models.contracts", []).service("ContractsModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            contractsMeta,
            settings = {
                isClassOpen: true,
                isDatesOpem: true,
                isInstructorOpen: true,
                isCompensationOpen: true,
                isNotesOpen: true,
                formLayout: "tabs",
            },
            API = {
                /**
                 * Contracts
                 */

                getContract: function (contractId) {
                    return $http.get(API_URL + "contracts/" + contractId);
                },
                updateContract: function (contract) {
                    return $http.patch(API_URL + "contracts/" + contract.id, contract);
                },
                replaceContracts: function (data) {
                    return $http.patch(API_URL + "contracts/replace", data);
                },
                deleteContracts: function (contracts) {
                    return $http.post(API_URL + "contracts/delete", contracts);
                },
                deleteContract: function (contractId) {
                    return $http.delete(API_URL + "contracts/" + contractId);
                },
                duplicateContract: function (contractId, newContract) {
                    return $http.post(API_URL + "contracts/" + contractId + "/duplicate", newContract);
                },
                createContractRevision: function (contract) {
                    return $http.post(API_URL + "contracts/" + contract.id + "/revisions", contract);
                },
                updateContractInstructor: function (contractId, instructor) {
                    return $http.patch(API_URL + "contracts/" + contractId + "/instructor", instructor);
                },
                cancelContract: function (contract) {
                    return $http.post(API_URL + "contracts/" + contract.id + "/cancel", contract);
                },

                /**
                 * Contracts Meta
                 */

                getContractsMeta: function () {
                    return $http.get(API_URL + "contracts/meta");
                },
                createContractsMeta: function (meta) {
                    return $http.post(API_URL + "contracts/meta", meta);
                },
                updateContractsMeta: function (meta) {
                    return $http.patch(API_URL + "contracts/meta/" + meta.id, meta);
                },
                deleteContractsMeta: function (meta) {
                    return $http.delete(API_URL + "contracts/meta/" + meta.id);
                },

                /*
                 * Contract Notes
                 */
                createContractNote: function (contractId, note) {
                    return $http.post(API_URL + "contracts/" + contractId + "/notes", note);
                },
                createContractsNote: function (data) {
                    return $http.post(API_URL + "contracts/notes", data);
                },
                deleteContractNote: function (contractId, contractNoteId) {
                    return $http.delete(API_URL + "contracts/" + contractId + "/notes/" + contractNoteId);
                },

                /**
                 * Contract Revision
                 */
                previewContractRevision: function (revision) {
                    return $http.get(API_URL + "contracts/" + revision.id + "/preview", { params: revision });
                },
                previewContractRevisionEmail: function (revision) {
                    return $http.post(API_URL + "contracts/" + revision.parent_id + "/email-preview", revision);
                },

                /**
                 * Contract Audits
                 */

                getAudits: function (contractId, params) {
                    return $http.get(API_URL + "contracts/" + contractId + "/audits", { params: params });
                },
            };

        model.getSettings = function () {
            return settings;
        };

        model.predefinedFields = function () {
            return ["account_no_sem", "account_dept_id", "account_no_dept", "account_class", "account_fund"];
        };

        model.fields = function (semester) {
            var fields = [
                "college",
                "department",
                "subject",
                "course_catalog",
                "course_title",

                "is_dll_support_approved",

                "section_no",
                "section_mode",
                "section_offer_no",
                "section_component",
                "section_min_units",
                "section_max_units",

                "lesson_no",
                "lesson_status",
                "lesson_campus",
                "lesson_comb_sects_id",
                "lesson_session",
                "lesson_weeks",
                "is_lesson_team_taught",
                "is_lesson_duplicate",
                "is_lesson_coop_educ",
                "is_lesson_remote_taught",
                "is_lesson_appl_sci",
                "is_lesson_art_cr_exp",
                "is_lesson_cap_exp",
                "is_lesson_cul_diver",
                "is_lesson_ethics",
                "is_lesson_pop_env",
                "is_lesson_quant_lit",
                "is_lesson_sci_know",
                "is_lesson_soc_cont",
                "is_lesson_west_cul",
                "is_lesson_writing_intensive",

                "lesson_start_date",
                "lesson_end_date",

                "lesson_facil_id_one",
                "lesson_days_one",
                "lesson_start_date_one",
                "lesson_end_date_one",
                "lesson_start_time_one",
                "lesson_end_time_one",

                "lesson_facil_id_two",
                "lesson_days_two",
                "lesson_start_date_two",
                "lesson_end_date_two",
                "lesson_start_time_two",
                "lesson_end_time_two",

                "lesson_facil_id_three",
                "lesson_days_three",
                "lesson_start_date_three",
                "lesson_end_date_three",
                "lesson_start_time_three",
                "lesson_end_time_three",

                "lesson_facil_id_four",
                "lesson_days_four",
                "lesson_start_date_four",
                "lesson_end_date_four",
                "lesson_start_time_four",
                "lesson_end_time_four",

                "lesson_facil_id_five",
                "lesson_days_five",
                "lesson_start_date_five",
                "lesson_end_date_five",
                "lesson_start_time_five",
                "lesson_end_time_five",

                "lesson_last_day_add",
                "lesson_last_day_drop",
                "lesson_last_day_add_enrl",
                "lesson_last_day_drop_enrl",
                "lesson_enrl_total",
                "lesson_enrl_total_combined",
                "lesson_enrl_cap",

                "user_ums_id",
                "user_email",
                "user_first_name",
                "user_last_name",

                "instructor_address",
                "instructor_city",
                "instructor_state",
                "instructor_zip",
                "instructor_college",
                "instructor_department",
                "instructor_rank",
                "instructor_unit",
                "instructor_position_no",
                "instructor_credit_hr_rate",
                "instructor_patfa_service_credits",
                "instructor_months_in_work_year",
                "instructor_job_code",
                "instructor_is_online_faculty",
                "instructor_is_gold_faculty",
                "instructor_is_de_adj_eligible",
                "instructor_is_de_adj_grandfathered",
                "instructor_has_fhc_fee",

                "instructor_notes_content",

                "account_no_sem",
                "account_dept_id",
                "account_no_dept",
                "account_class",
                "account_fund",
                "account_project",
                "account_program",
                "account_amount",

                "account_no_sem_two",
                "account_dept_id_two",
                "account_no_dept_two",
                "account_class_two",
                "account_fund_two",
                "account_project_two",
                "account_program_two",
                "account_amount_two",

                "account_no_sem_three",
                "account_dept_id_three",
                "account_no_dept_three",
                "account_class_three",
                "account_fund_three",
                "account_project_three",
                "account_program_three",
                "account_amount_three",

                "has_prorated_salary",
                "prorated_salary_enrl",
                "prorated_salary_date",

                "is_canc_warn_disabled",
                "has_canc_warned",
                "canc_warned_enrl",
                "canc_warned_date",

                "is_canc",
                "canc_enrl",
                "canc_pay_percentage",
                "canc_date",

                "has_de_adj",
                "de_adj_enrl",
                "de_adj_overload",
                "de_adj_date",

                "new_rate_add",
                "additional_lab_hrs",
                "fhc_add_comp",

                "is_first_time_taught_dist",
                "course_prep_fee_amount",
                "course_prep_fee_payroll_date",
                "course_prep_fee_paid_term",

                "salary",
                "payments",
                "pay_start_date",
                "pay_end_date",
                "monthly",
                "hourly_rate",
                "payroll_date",

                "adj_salary",
                "adj_payments",
                "adj_pay_start_date",
                "adj_pay_end_date",
                "adj_monthly",
                "adj_hourly_rate",
                "adj_payroll_date",
                "amount_prev_paid",

                "created_at",
                "updated_at",
            ];

            var months = ["month_one", "month_two", "month_three", "month_four", "month_five"];

            _.forEach(fields, function (field, i) {
                if (field == "amount_prev_paid") {
                    if (semester) {
                        var term = semester.term.name.substring(0, 2).toUpperCase();
                        var year = String(semester.year).substring(2, 4);

                        _.forEach(semester.term.months, function (month, j) {
                            fields.splice(i + 1 + j, 0, months[j]);
                        });
                    } else {
                        _.forEach(months, function (month, j) {
                            fields.splice(i + 1 + j, 0, months[j]);
                        });
                    }
                }
            });

            return fields;
        };

        model.fillable = function (semester) {
            var fillable = [
                "semester_term_code",
                "is_monthly_calculated",

                "notes_content",

                "is_completed",
                "is_approved",
                "is_accepted",

                "in_proposal",

                "chair_updated_at",
                "dean_updated_at",
                "grad_dean_updated_at",
                "provost_updated_at",
                "chancellor_updated_at",

                "chair_email",
                "dean_email",
                "grad_dean_email",
                "provost_email",
                "chancellor_email",

                "sent_at",
                "returned_at",
            ];

            return _.concat(model.fields(semester), fillable);
        };

        model.protected = function () {
            return [
                "semester_term_code",

                "is_lesson_team_taught",
                "is_lesson_duplicate",

                "lesson_enrl_total_combined",

                "user_ums_id",

                "monthly",
                "hourly_rate",

                "adj_salary",
                "adj_payments",
                "adj_pay_start_date",
                "adj_pay_end_date",
                "adj_monthly",
                "adj_hourly_rate",
                "amount_prev_paid",

                "is_monthly_calculated",

                "is_completed",
                "is_approved",
                "is_accepted",
                "in_proposal",

                "chair_updated_at",
                "dean_updated_at",
                "grad_dean_updated_at",
                "provost_updated_at",
                "chancellor_updated_at",

                "chair_email",
                "dean_email",
                "grad_dean_email",
                "provost_email",
                "chancellor_email",

                "notes_content",
                "instructor_notes_content",

                "sent_at",
                "returned_at",
                "created_at",
                "updated_at",
            ];
        };

        model.labels = function (semester) {
            var labels = {
                id: "ID",
                parent_id: "Parent ID",
                semester_id: "Semester ID",
                user_id: "User ID",

                college: "College",
                department: "Dept",
                subject: "Subject",
                course_catalog: "Catalog",
                course_title: "Course Title",
                course: "Course",

                is_dll_support_approved: "DLL Support Approved?",

                section_no: "Section",
                section_mode: "Mode",
                section_offer_no: "Offer Nbr",
                section_component: "Component",
                section_min_units: "Min Units",
                section_max_units: "Max Units",

                lesson_no: "Class Nbr",
                lesson_status: "Class Status",
                lesson_campus: "Campus",
                lesson_comb_sects_id: "Comb Sects ID",
                lesson_session: "Session",
                lesson_weeks: "Class Weeks",

                is_lesson_team_taught: "Team Taught?",
                is_lesson_duplicate: "Duplicate Lesson?",
                is_lesson_coop_educ: "Coop Educ Intern?",
                is_lesson_remote_taught: "Remote Taught?",

                is_lesson_appl_sci: "APPL SCI",
                is_lesson_art_cr_exp: "ART CR EXP",
                is_lesson_cap_exp: "CAP EXP",
                is_lesson_cul_diver: "CUL DIVER",
                is_lesson_ethics: "ETHICS",
                is_lesson_pop_env: "POP & ENV",
                is_lesson_quant_lit: "QUANT LIT",
                is_lesson_sci_know: "SCI KNOW",
                is_lesson_soc_cont: "SOC CONT",
                is_lesson_west_cul: "WEST CUL",
                is_lesson_writing_intensive: "WRIT INTEN",

                lesson_start_date: "Start Date",
                lesson_end_date: "End Date",

                lesson_facil_id_one: "Facil ID 1",
                lesson_days_one: "Days 1",
                lesson_start_date_one: "Start Date 1",
                lesson_end_date_one: "End Date 1",
                lesson_start_time_one: "Mtg Start 1",
                lesson_end_time_one: "Mtg End 1",

                lesson_facil_id_two: "Facil ID 2",
                lesson_days_two: "Days 2",
                lesson_start_date_two: "Start Date 2",
                lesson_end_date_two: "End Date 2",
                lesson_start_time_two: "Mtg Start 2",
                lesson_end_time_two: "Mtg End 2",

                lesson_facil_id_three: "Facil ID 3",
                lesson_days_three: "Days 3",
                lesson_start_date_three: "Start Date 3",
                lesson_end_date_three: "End Date 3",
                lesson_start_time_three: "Mtg Start 3",
                lesson_end_time_three: "Mtg End 3",

                lesson_facil_id_four: "Facil ID 4",
                lesson_days_four: "Days 4",
                lesson_start_date_four: "Start Date 4",
                lesson_end_date_four: "End Date 4",
                lesson_start_time_four: "Mtg Start 4",
                lesson_end_time_four: "Mtg End 4",

                lesson_facil_id_five: "Facil ID 5",
                lesson_days_five: "Days 5",
                lesson_start_date_five: "Start Date 5",
                lesson_end_date_five: "End Date 5",
                lesson_start_time_five: "Mtg Start 5",
                lesson_end_time_five: "Mtg End 5",

                lesson_last_day_add: "Last Day to Add",
                lesson_last_day_drop: "Last Day to Drop",
                lesson_last_day_add_enrl: "Last Day to Add Enrl",
                lesson_last_day_drop_enrl: "Last Day to Drop Enrl",
                lesson_enrl_total: "Tot Enrl",
                lesson_enrl_total_combined: "Tot Enrl Combined",
                lesson_enrl_cap: "Enrl Cap",

                user_ums_id: "Employee ID",
                user_first_name: "First Name",
                user_last_name: "Last Name",
                user_email: "Email",

                instructor: "Instructor",
                instructor_address: "Address",
                instructor_city: "City",
                instructor_state: "State",
                instructor_zip: "Zip",
                instructor_college: "Instructor College",
                instructor_department: "Instructor Dept",
                instructor_rank: "Rank",
                instructor_unit: "Unit",
                instructor_position_no: "Position No.",
                instructor_credit_hr_rate: "Credit Hour Rate",
                instructor_patfa_service_credits: "PATFA Service Credits",
                instructor_months_in_work_year: "Months in Work Year",
                instructor_job_code: "Job Code",
                instructor_is_online_faculty: "Online Faculty?",
                instructor_is_gold_faculty: "GOLD Faculty?",
                instructor_is_de_adj_eligible: "DE Adj Eligible?",
                instructor_is_de_adj_grandfathered: "DE Adj Grandfathered?",
                instructor_has_fhc_fee: "FHC Fee?",

                instructor_notes_content: "Instructor Notes",

                account_no_sem: "Sem Account No.",
                account_dept_id: "Dept ID",
                account_no_dept: "Account",
                account_class: "Class",
                account_fund: "Fund",
                account_project: "Project",
                account_program: "Program",
                account_amount: "Amount",

                account_no_sem_two: "Sem Account No. 2",
                account_dept_id_two: "Dept ID 2",
                account_no_dept_two: "Account 2",
                account_class_two: "Class 2",
                account_fund_two: "Fund 2",
                account_project_two: "Project 2",
                account_program_two: "Program 2",
                account_amount_two: "Amount 2",

                account_no_sem_three: "Sem Account No. 3",
                account_dept_id_three: "Dept ID 3",
                account_no_dept_three: "Account 3",
                account_class_three: "Class 3",
                account_fund_three: "Fund 3",
                account_project_three: "Project 3",
                account_program_three: "Program 3",
                account_amount_three: "Amount 3",

                has_prorated_salary: "Prorated Salary?",
                prorated_salary_enrl: "Prorated Salary Enrl",
                prorated_salary_date: "Prorarted Salary Date",

                is_canc_warn_disabled: "Canc Warn Disabled?",
                has_canc_warned: "Canc Warned?",
                canc_warned_enrl: "Canc Warned Enrl",
                canc_warned_date: "Canc Warned Date",

                is_canc: "Canc Course?",
                canc_enrl: "Canc Enrl",
                canc_pay_percentage: "Canc Pay %",
                canc_date: "Canc Date",

                has_de_adj: "DE Adj?",
                de_adj_enrl: "DE Adj Enrl",
                de_adj_overload: "DE Adj Overload",
                de_adj_date: "DE Adj Date",

                new_rate_add: "New Rate Add",
                additional_lab_hrs: "Add'l Lab Hrs",
                fhc_add_comp: "FHC Add Comp",

                is_first_time_taught_dist: "First Time Taught Distance?",
                course_prep_fee_amount: "Course Prep Fee Amount",
                course_prep_fee_payroll_date: "Course Prep Fee to Payroll",
                course_prep_fee_paid_term: "Course Prep Fee Paid Term",

                salary: "Salary",
                payments: "No. Payments",
                pay_start_date: "Pay Start Date",
                pay_end_date: "Pay End Date",
                monthly: "Monthly",
                hourly_rate: "Hourly Rate",
                payroll_date: "Payroll Date",

                adj_salary: "Adj Salary",
                adj_payments: "Adj No. Payments",
                adj_pay_start_date: "Adj Pay Start Date",
                adj_pay_end_date: "Adj Pay End Date",
                adj_monthly: "Adj Monthly",
                adj_hourly_rate: "Adj Hourly Rate",
                adj_payroll_date: "Adj Payroll Date",
                amount_prev_paid: "Amount Previously Paid",

                month_one: "Month 1",
                month_two: "Month 2",
                month_three: "Month 3",
                month_four: "Month 4",
                month_five: "Month 5",

                type: "Type",
                class_correction: "Information Adjustment",
                salary_correction: "Salary Correction",
                salary_adj: "Salary Adjustment",
                de_adj: "DE Adjustment",
                prorated_salary: "Prorated Salary",
                canc: "Cancelled Course",
                canc_no_fee: "Cancelled Course (No Fee)",
                instructor_change: "Change in Instructor",

                status: "Status",
                reminders: "Reminders",
                ip: "IP",
                status_updated_at: "Status Updated",
                created_at: "Created",
                updated_at: "Updated",
                max_updated_at: "Updated",

                semester_term_code: "STRM",

                is_monthly_calculated: "Monthly Calculated?",

                notes_content: "Notes",

                is_completed: "Completion Status",
                is_approved: "Approval Status",
                is_accepted: "Acceptance Status",

                in_proposal: "In Proposal?",

                chair_updated_at: "Chair",
                dean_updated_at: "UnderG Dean",
                grad_dean_updated_at: "Grad Dean",
                provost_updated_at: "Provost",
                chancellor_updated_at: "Chancellor",

                chair_email: "Chair Email",
                dean_email: "Dean Email",
                grad_dean_email: "Graduate Email",
                provost_email: "Provost Email",
                chancellor_email: "Chancellor Email",

                sent_at: "Contract Sent",
                returned_at: "Contract Returned",
            };

            if (semester) {
                var months = ["month_one", "month_two", "month_three", "month_four", "month_five"];
                var term = semester.term.name.substring(0, 2).toUpperCase();
                var year = String(semester.year).substring(2, 4);
                _.forEach(semester.term.months, function (month, i) {
                    labels[months[i]] = month.short;
                });
            }

            return labels;
        };

        model.groups = function () {
            return {
                semester_term_code: "Class",

                college: "Class",
                department: "Class",
                subject: "Class",
                course_catalog: "Class",
                course_title: "Class",

                is_dll_support_approved: "Class",

                section_no: "Class",
                section_mode: "Class",
                section_offer_no: "Class",
                section_component: "Class",
                section_min_units: "Class",
                section_max_units: "Class",

                lesson_no: "Class",
                lesson_status: "Class",
                lesson_campus: "Class",
                lesson_comb_sects_id: "Class",
                lesson_session: "Class",
                lesson_weeks: "Class",

                is_lesson_team_taught: "Class",
                is_lesson_duplicate: "Class",
                is_lesson_appl_sci: "Class",
                is_lesson_art_cr_exp: "Class",
                is_lesson_cap_exp: "Class",
                is_lesson_cul_diver: "Class",
                is_lesson_ethics: "Class",
                is_lesson_pop_env: "Class",
                is_lesson_quant_lit: "Class",
                is_lesson_sci_know: "Class",
                is_lesson_soc_cont: "Class",
                is_lesson_west_cul: "Class",
                is_lesson_writing_intensive: "Class",
                is_lesson_coop_educ: "Class",
                is_lesson_remote_taught: "Class",

                lesson_start_date: "Class",
                lesson_end_date: "Class",

                lesson_facil_id_one: "Class",
                lesson_days_one: "Class",
                lesson_start_date_one: "Class",
                lesson_end_date_one: "Class",
                lesson_start_time_one: "Class",
                lesson_end_time_one: "Class",

                lesson_facil_id_two: "Class",
                lesson_days_two: "Class",
                lesson_start_date_two: "Class",
                lesson_end_date_two: "Class",
                lesson_start_time_two: "Class",
                lesson_end_time_two: "Class",

                lesson_facil_id_three: "Class",
                lesson_days_three: "Class",
                lesson_start_date_three: "Class",
                lesson_end_date_three: "Class",
                lesson_start_time_three: "Class",
                lesson_end_time_three: "Class",

                lesson_facil_id_four: "Class",
                lesson_days_four: "Class",
                lesson_start_date_four: "Class",
                lesson_end_date_four: "Class",
                lesson_start_time_four: "Class",
                lesson_end_time_four: "Class",

                lesson_facil_id_five: "Class",
                lesson_days_five: "Class",
                lesson_start_date_five: "Class",
                lesson_end_date_five: "Class",
                lesson_start_time_five: "Class",
                lesson_end_time_five: "Class",

                lesson_last_day_add: "Class",
                lesson_last_day_drop: "Class",
                lesson_last_day_add_enrl: "Class",
                lesson_last_day_drop_enrl: "Class",
                lesson_enrl_total: "Class",
                lesson_enrl_total_combined: "Class",
                lesson_enrl_cap: "Class",

                user_ums_id: "Instructor",
                user_first_name: "Instructor",
                user_last_name: "Instructor",
                user_email: "Instructor",

                instructor_address: "Instructor",
                instructor_city: "Instructor",
                instructor_state: "Instructor",
                instructor_zip: "Instructor",
                instructor_college: "Instructor",
                instructor_department: "Instructor",
                instructor_rank: "Instructor",
                instructor_unit: "Instructor",
                instructor_position_no: "Instructor",
                instructor_credit_hr_rate: "Instructor",
                instructor_patfa_service_credits: "Instructor",
                instructor_months_in_work_year: "Instructor",
                instructor_job_code: "Instructor",
                instructor_is_online_faculty: "Instructor",
                instructor_is_gold_faculty: "Instructor",
                instructor_is_de_adj_eligible: "Instructor",
                instructor_is_de_adj_grandfathered: "Instructor",
                instructor_has_fhc_fee: "Instructor",
                instructor_notes_content: "Instructor",

                account_no_sem: "Compensation",
                account_dept_id: "Compensation",
                account_no_dept: "Compensation",
                account_class: "Compensation",
                account_fund: "Compensation",
                account_project: "Compensation",
                account_program: "Compensation",
                account_amount: "Compensation",

                account_no_sem_two: "Compensation",
                account_dept_id_two: "Compensation",
                account_no_dept_two: "Compensation",
                account_class_two: "Compensation",
                account_fund_two: "Compensation",
                account_project_two: "Compensation",
                account_program_two: "Compensation",
                account_amount_two: "Compensation",

                account_no_sem_three: "Compensation",
                account_dept_id_three: "Compensation",
                account_no_dept_three: "Compensation",
                account_class_three: "Compensation",
                account_fund_three: "Compensation",
                account_project_three: "Compensation",
                account_program_three: "Compensation",
                account_amount_three: "Compensation",

                has_prorated_salary: "Compensation",
                prorated_salary_enrl: "Compensation",
                prorated_salary_date: "Compensation",

                is_canc_warn_disabled: "Compensation",
                has_canc_warned: "Compensation",
                canc_warned_enrl: "Compensation",
                canc_warned_date: "Compensation",

                is_canc: "Compensation",
                canc_enrl: "Compensation",
                canc_pay_percentage: "Compensation",
                canc_date: "Compensation",

                has_de_adj: "Compensation",
                de_adj_enrl: "Compensation",
                de_adj_overload: "Compensation",
                de_adj_date: "Compensation",

                new_rate_add: "Compensation",
                additional_lab_hrs: "Compensation",
                fhc_add_comp: "Compensation",

                is_first_time_taught_dist: "Compensation",
                course_prep_fee_amount: "Compensation",
                course_prep_fee_payroll_date: "Compensation",
                course_prep_fee_paid_term: "Compensation",

                salary: "Compensation",
                payments: "Compensation",
                pay_start_date: "Compensation",
                pay_end_date: "Compensation",
                monthly: "Compensation",
                hourly_rate: "Compensation",
                payroll_date: "Compensation",

                adj_salary: "Compensation",
                adj_payments: "Compensation",
                adj_pay_start_date: "Compensation",
                adj_pay_end_date: "Compensation",
                adj_monthly: "Compensation",
                adj_hourly_rate: "Compensation",
                adj_payroll_date: "Compensation",
                amount_prev_paid: "Compensation",

                is_monthly_calculated: "Compensation",

                month_one: "Compensation",
                month_two: "Compensation",
                month_three: "Compensation",
                month_four: "Compensation",
                month_five: "Compensation",

                notes_content: "Notes",

                is_completed: "Status",
                is_approved: "Status",
                is_accepted: "Status",

                in_proposal: "Confirmation",

                chair_updated_at: "Confirmation",
                dean_updated_at: "Confirmation",
                grad_dean_updated_at: "Confirmation",
                provost_updated_at: "Confirmation",
                chancellor_updated_at: "Confirmation",

                chair_email: "Confirmation",
                dean_email: "Confirmation",
                grad_dean_email: "Confirmation",
                provost_email: "Confirmation",
                chancellor_email: "Confirmation",

                sent_at: "Confirmation",
                returned_at: "Confirmation",
                created_at: "Confirmation",
                updated_at: "Confirmation",
            };
        };

        model.dates = function () {
            return [
                "lesson_start_date",
                "lesson_end_date",

                "lesson_start_date_one",
                "lesson_end_date_one",

                "lesson_start_date_two",
                "lesson_end_date_two",

                "lesson_start_date_three",
                "lesson_end_date_three",

                "lesson_start_date_four",
                "lesson_end_date_four",

                "lesson_start_date_five",
                "lesson_end_date_five",

                "lesson_last_day_add",
                "lesson_last_day_drop",

                "prorated_salary_date",
                "canc_date",
                "canc_warned_date",
                "de_adj_date",
                "course_prep_fee_payroll_date",

                "pay_start_date",
                "pay_end_date",
                "payroll_date",

                "adj_pay_start_date",
                "adj_pay_end_date",
                "adj_payroll_date",

                "chair_updated_at",
                "dean_updated_at",
                "grad_dean_updated_at",
                "provost_updated_at",
                "chancellor_updated_at",

                "sent_at",
                "returned_at",
                "created_at",
                "updated_at",

                "status_updated_at",
            ];
        };

        model.times = function () {
            return [
                "lesson_start_time_one",
                "lesson_end_time_one",

                "lesson_start_time_two",
                "lesson_end_time_two",

                "lesson_start_time_three",
                "lesson_end_time_three",

                "lesson_start_time_four",
                "lesson_end_time_four",

                "lesson_start_time_five",
                "lesson_end_time_five",
            ];
        };

        model.numbers = function () {
            return [
                "id",

                "semester_term_code",

                "course_catalog",

                "lesson_no",

                "section_no",
                "section_offer_no",
                "section_min_units",
                "section_max_units",

                "lesson_weeks",

                "lesson_enrl_total",
                "lesson_enrl_total_combined",
                "lesson_enrl_cap",

                "instructor_credit_hr_rate",
                "instructor_patfa_service_credits",
                "instructor_months_in_work_year",

                "prorated_salary_enrl",

                "canc_warned_enrl",

                "canc_enrl",
                "canc_pay_percentage",

                "de_adj_enrl",
                "de_adj_overload",

                "new_rate_add",
                "additional_lab_hrs",
                "fhc_add_comp",

                "course_prep_fee_amount",

                "salary",
                "payments",
                "monthly",
                "hourly_rate",

                "adj_salary",
                "adj_payments",
                "adj_monthly",
                "adj_hourly",
                "amount_prev_paid",

                "month_one",
                "month_two",
                "month_three",
                "month_four",
                "month_five",
            ];
        };

        model.decimals = function () {
            return [
                "section_min_units",
                "section_max_units",
                "instructor_credit_hr_rate",
                "account_amount",
                "account_amount_two",
                "account_amount_three",
                "canc_pay_percentage",
                "de_adj_overload",
                "new_rate_add",
                "additional_lab_hrs",
                "fhc_add_comp",
                "course_prep_fee_amount",
                "salary",
                "adj_salary",
                "amount_prev_paid",
                "month_one",
                "month_two",
                "month_three",
                "month_four",
                "month_five",
                "monthly",
                "hourly_rate",
                "adj_monthly",
                "adj_hourly",
            ];
        };

        model.booleans = function () {
            return [
                "is_dll_support_approved",

                "is_lesson_team_taught",
                "is_lesson_duplicate",
                "is_lesson_appl_sci",
                "is_lesson_art_cr_exp",
                "is_lesson_cap_exp",
                "is_lesson_cul_diver",
                "is_lesson_ethics",
                "is_lesson_pop_env",
                "is_lesson_quant_lit",
                "is_lesson_sci_know",
                "is_lesson_soc_cont",
                "is_lesson_west_cul",
                "is_lesson_writing_intensive",
                "is_lesson_coop_educ",
                "is_lesson_remote_taught",

                "instructor_is_online_faculty",
                "instructor_is_gold_faculty",
                "instructor_is_de_adj_eligible",
                "instructor_is_de_adj_grandfathered",
                "instructor_has_fhc_fee",

                "has_prorated_salary",

                "is_canc_warn_disabled",
                "has_canc_warned",
                "is_canc",

                "has_de_adj",

                "is_first_time_taught_dist",

                "is_monthly_calculated",

                "in_proposal",
            ];
        };

        model.types = function () {
            return [
                "class_correction",
                "salary_correction",
                "salary_adj",
                "de_adj",
                "prorated_salary",
                "canc",
                "canc_no_fee",
                "instructor_change",
            ];
        };

        model.statuses = function () {
            return ["is_completed", "is_approved", "is_accepted"];
        };

        model.lessonStatuses = function () {
            return [
                {
                    label: "Active",
                    value: "Active",
                },
                {
                    label: "Cancelled",
                    value: "Cancelled",
                },
                {
                    label: "Stop Enrl",
                    value: "Stop Enrl",
                },
            ];
        };

        model.selectOptions = function (isNullable) {
            var selectOptions = [
                {
                    label: "Yes",
                    value: true,
                },
                {
                    label: "No",
                    value: false,
                },
            ];

            if (isNullable) {
                selectOptions.push({
                    label: "Pending",
                    value: null,
                });
            }

            return selectOptions;
        };

        function extract(result) {
            return result.data;
        }

        /**
         * Contract
         */

        model.getContract = function (contractId) {
            var deferred = $q.defer();

            API.getContract(contractId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.previewContractRevision = function (revision) {
            var deferred = $q.defer();

            API.previewContractRevision(revision).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.previewContractRevisionEmail = function (revision) {
            var deferred = $q.defer();

            API.previewContractRevisionEmail(revision).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.updateContract = function (contract) {
            var deferred = $q.defer();

            API.updateContract(contract).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.replaceContracts = function (contracts, replacement) {
            var deferred = $q.defer();

            var data = replacement;
            data.contracts = contracts;

            API.replaceContracts(data).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.resolve(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteContracts = function (semesterId, contracts) {
            var deferred = $q.defer();

            API.deleteContracts(semesterId, contracts).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteContract = function (contractId) {
            var deferred = $q.defer();

            API.deleteContract(contractId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.duplicateContract = function (contractId, newContract) {
            var deferred = $q.defer();

            API.duplicateContract(contractId, newContract).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.createContractRevision = function (contract) {
            var deferred = $q.defer();

            API.createContractRevision(contract).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.updateContractInstructor = function (contractId, instructor) {
            var deferred = $q.defer();

            API.updateContractInstructor(contractId, instructor).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.cancelContract = function (contract) {
            var deferred = $q.defer();

            API.cancelContract(contract).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Contracts Meta
         */

        function cacheContractsMeta(result) {
            contractsMeta = extract(result);

            return contractsMeta;
        }

        model.createContractsMeta = function (meta) {
            var deferred = $q.defer();

            API.createContractsMeta(meta).then(
                function (result) {
                    meta = extract(result);
                    contractsMeta.push(meta);
                    deferred.resolve(meta);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getContractsMeta = function () {
            var deferred = $q.defer();

            if (contractsMeta) {
                $q.when(contractsMeta).then(function () {
                    deferred.resolve(contractsMeta);
                });
            } else {
                contractsMeta = API.getContractsMeta().then(function (result) {
                    return cacheContractsMeta(result);
                });
                deferred.resolve(contractsMeta);
            }

            return deferred.promise;
        };

        model.updateContractsMeta = function (meta) {
            var deferred = $q.defer();

            API.updateContractsMeta(meta).then(
                function (result) {
                    meta = extract(result);
                    var contractMeta = _.find(contractsMeta, function (contractMeta) {
                        return contractMeta.id == meta.id;
                    });
                    if (contractMeta) {
                        _.assign(contractMeta, meta);
                    }
                    deferred.resolve(meta);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteContractsMeta = function (meta) {
            var deferred = $q.defer();

            API.deleteContractsMeta(meta).then(
                function (result) {
                    _.remove(contractsMeta, function (_meta) {
                        return _meta.id === meta.id;
                    });
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /*
         * Contract Notes
         */

        model.createContractNote = function (contractId, newNote) {
            var deferred = $q.defer();

            API.createContractNote(contractId, newNote).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.createContractsNote = function (contracts, newNote) {
            var deferred = $q.defer();

            var data = newNote;
            data.contracts = contracts;

            API.createContractsNote(data).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteContractNote = function (contractId, contractNoteId) {
            var deferred = $q.defer();

            API.deleteContractNote(contractId, contractNoteId).then(
                function () {
                    deferred.resolve();
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getAudits = function (contractId, params) {
            var deferred = $q.defer();

            API.getAudits(contractId, params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };
    },
]);
